import { Modal } from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import { ContextData } from "../../context/Context";
import ritividhi from "../../assets/ritividhilogo.png";
import cross from "../../assets/cross.svg";
import LazyLoad from "react-lazyload";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  GoogleAuthProvider,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signInWithPopup,
} from "firebase/auth";
import { auth } from "../../firebase/setup";
import OTPInput from "react-otp-input";
import google from "../../assets/google.png";
import axios from "axios";
import toast from "react-hot-toast";
import loading from "../../assets/loading.gif";

function LoginAccountModal() {
  const {
    openloginmodal,
    setOpenloginmodal,
    setLoggedIn,
    login,
    setLogin,
    setuserid,
  } = useContext(ContextData);

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errors, setErrors] = useState({});
  const [OTP, setOTP] = useState("");
  const [showOTP, setShowOTP] = useState(false);
  const [count, setCount] = useState(0);
  const [isActive, setIsActive] = useState(false);

  const [signupLoading, setsignuploading] = useState(false);
  const [loginLoading, setloginloading] = useState(false);

  let mail = "";
  let mailname = "";

  const handleClose = () => {
    setOpenloginmodal(false);
    setName("");
    setOTP("");
    setPhoneNumber("");
    setShowOTP(false);
    setCount(0);
    setsignuploading(false);
    setloginloading(false);
  };

  const handleback = () => {
    setOTP("");
    setPhoneNumber("");
    setShowOTP(false);
    setsignuploading(false);
    setloginloading(false);
  };

  const handleResend = () => {
    setOTP("");
    onSignup();
  };

  const validateSignUpInputs = async () => {
    let inputErrors = {};

    if (!name.trim()) {
      inputErrors.name = "Name is required";
    }

    if (!/^\+91\d{10}$/.test(phoneNumber)) {
      inputErrors.phoneNumber =
        "Phone number must be in the format +91(Number)";
    } else {
      const isDuplicate = await checkPhoneNumberExists(phoneNumber);
      if (isDuplicate) {
        inputErrors.phoneNumber = "Phone number already exists";
      }
    }

    setErrors(inputErrors);
    return Object.keys(inputErrors).length === 0;
  };

  const validateLoginInputs = async () => {
    let inputErrors = {};

    if (!/^\+91\d{10}$/.test(phoneNumber)) {
      inputErrors.phoneNumber =
        "Phone number must be in the format +91(Number)";
    } else {
      const isDuplicate = await checkPhoneNumberExists(phoneNumber);
      if (!isDuplicate) {
        inputErrors.phoneNumber = "Number not present";
      }
    }
    setErrors(inputErrors);
    return Object.keys(inputErrors).length === 0;
  };

  const checkPhoneNumberExists = async (number) => {
    const normalizedNumber = number.replace(/^\+91/, "");

    try {
      const response = await fetch("https://app.ritividhi.com/ritividhi/users");
      if (!response.ok) {
        throw new Error("Failed to fetch user data");
      }
      const data = await response.json();

      return data.some((user) => user.phone === normalizedNumber);
    } catch (error) {
      console.error("Error checking phone number:", error);
      return false;
    }
  };

  const handlegoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        setLoggedIn(true);
        setPhoneNumber("");
        handleClose();
        setOTP("");
        setShowOTP(false);
        mail = result.user.email;
        mailname = result.user.displayName;
        localStorage.setItem("token", result.user.accessToken);
        handlecreateUser(mail, mailname);
      })
      .catch((error) => {
        console.error("Login failed:", error);
      });
  };

  const startTimer = () => {
    setIsActive(true);
  };

  useEffect(() => {
    let timer;
    if (isActive && count > 0) {
      timer = setInterval(() => {
        setCount((prevCount) => prevCount - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isActive, count]);

  const onCaptchVerify = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, "sign-in-button", {
        size: "invisible",
        callback: () => {
          window.recaptchaVerifier.recaptcha.reset();
        },
        "expired-callback": () => {
          window.recaptchaVerifier.recaptcha.reset();
        },
        "error-callback": () => {
          window.recaptchaVerifier.recaptcha.reset();
        },
      });
    }
  };

  const onSignup = () => {
    onCaptchVerify();
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setShowOTP(true);
        setCount(60);
        startTimer();
        setsignuploading(false);
        setloginloading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onOTPVerify = () => {
    setsignuploading(true);
    setloginloading(true);
    window.confirmationResult
      .confirm(OTP)
      .then(async (res) => {
        setLoggedIn(true);
        setPhoneNumber("");
        handleClose();
        setOTP("");
        setShowOTP(false);
        localStorage.setItem("token", res.user.accessToken);
        handlecreateUser();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleLogin = async () => {
    setloginloading(true);
    const isValid = await validateLoginInputs();
    if (isValid) {
      onSignup();
    } else {
      toast("Sign Up to continue", {
        icon: "🔔",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      handleSignupChange();
    }
  };

  const handleSignUp = async () => {
    setsignuploading(true);
    const isValid = await validateSignUpInputs();
    if (isValid) {
      onSignup();
    } else {
      toast("Log In to continue", {
        icon: "👍",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      handleSignupChange();
    }
  };

  const handleSignupChange = () => {
    setsignuploading(false);
    setloginloading(false);
    setOTP("");
    setShowOTP(false);
    setCount(0);
    setErrors({});
    setPhoneNumber("");
    setName("");
    setLogin(!login);
  };

  const handlecreateUser = async (mail, mailname) => {
    let token = localStorage.getItem("token");
    if (mail && mailname && token) {
      try {
        const res = await axios({
          method: "post",
          url: "https://app.ritividhi.com/ritividhi/users/createUser",
          data: {
            name: mailname,
            email: mail,
            phone: "",
            role: [],
            eventName: "",
            eventDate: "",
            gender: "",
            address: {},
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        localStorage.setItem("userId", res.data.userId);
        setuserid(res.data.userId);
      } catch (error) {
        console.error("Error occurred during the request", error);
      }
    }

    if ((name || login) && phoneNumber && token) {
      try {
        const normalizedNumber = phoneNumber.replace(/^\+91/, "");
        const res = await axios({
          method: "post",
          url: "https://app.ritividhi.com/ritividhi/users/createUser",
          data: {
            name: name,
            email: "",
            phone: normalizedNumber,
            role: [],
            eventName: "",
            eventDate: "",
            gender: "",
            address: {},
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setloginloading(false);
        setsignuploading(false);
        localStorage.setItem("userId", res.data.userId);
        setuserid(res.data.userId);
      } catch (error) {
        console.error("Error occurred during the request", error);
      }
    }
  };

  return (
    <>
      <div id="sign-in-button"></div>
      {login ? (
        <Modal
          open={openloginmodal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          disableEnforceFocus
          className="bg-[#00000099]"
        >
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[400px] bg-white border-2 border-[#FF8213CC] shadow-xl px-8 py-6 rounded-[10px]">
            <div id="modal-modal-description" className="flex flex-col gap-4">
              <div className="w-full flex flex-row justify-between items-center">
                <LazyLoad height={20}>
                  <img
                    src={ritividhi}
                    alt="RitiVidhi logo"
                    className="size-20"
                  />
                </LazyLoad>
                <LazyLoad height={20}>
                  <img
                    src={cross}
                    alt="Close"
                    className="size-8 cursor-pointer"
                    onClick={handleClose}
                  />
                </LazyLoad>
              </div>
              {showOTP ? (
                <>
                  <h1 className="font-sans text-[20px] text-[#000000] font-bold">
                    Login to check your booking
                  </h1>
                  <span className="pt-2 text-BLACK-60 text-[12px] w-full font-normal text-left mb-4">
                    Please login with the same number that you have used for
                    booking.
                  </span>
                  <OTPInput
                    value={OTP}
                    onChange={setOTP}
                    numInputs={6}
                    otpType="number"
                    disabled={false}
                    renderSeparator={<span className="mx-2">-</span>}
                    renderInput={(props) => (
                      <input
                        {...props}
                        className="flex-1 mx-1 text-center border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                    )}
                  />

                  <div className="w-full flex justify-center text-[20px]">
                    {count}
                  </div>

                  {errors.phoneNumber && (
                    <span className="text-red-500 text-[12px]">
                      {errors.phoneNumber}
                    </span>
                  )}
                  <span className="font-sans text-[14px] font-normal text-[#000000]">
                    By proceeding you agree to the <br />
                    <span className="text-[#FF8213]">
                      Terms & Conditions
                    </span>{" "}
                    and <span className="text-[#FF8213]">Privacy Policy</span>{" "}
                    of <span className="text-[#FF8213]">RitiVidhi</span>
                  </span>

                  {count < 1 ? (
                    <div className="flex flex-row justify-center items-center gap-4">
                      <button
                        onClick={() => handleback()}
                        className="w-full py-3 flex justify-center rounded-[10px] text-[18px] text-[#ffffff] bg-[#FF8213]"
                      >
                        Back
                      </button>
                      <button
                        onClick={() => handleResend()}
                        className="w-full py-3 flex justify-center rounded-[10px] text-[18px] text-[#ffffff] bg-[#FF8213]"
                      >
                        Resend OTP
                      </button>
                    </div>
                  ) : (
                    <div className="flex flex-row justify-center items-center gap-4">
                      <button
                        onClick={() => handleback()}
                        className="w-full py-3 flex justify-center rounded-[10px] text-[18px] text-[#ffffff] bg-[#FF8213]"
                      >
                        Back
                      </button>
                      {loginLoading ? (
                        <div className="w-full py-3 flex justify-center rounded-[10px] bg-[#fffffff]">
                          <img src={loading} alt="loading" className="size-8" />
                        </div>
                      ) : (
                        <button
                          className="w-full py-3 flex justify-center rounded-[10px] text-[18px] text-[#ffffff] bg-[#FF8213]"
                          onClick={onOTPVerify}
                        >
                          Verfiy
                        </button>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <>
                  <h1 className="font-sans text-[20px] text-[#000000] font-bold">
                    Login to check your booking
                  </h1>
                  <span className="pt-2 text-BLACK-60 text-[12px] w-full font-normal text-left mb-4">
                    Please login with the same number that you have used for
                    booking.
                  </span>
                  <PhoneInput
                    country={"in"}
                    value={phoneNumber}
                    onChange={(phoneNumber) =>
                      setPhoneNumber("+" + phoneNumber)
                    }
                    inputProps={{
                      placeholder: "Phone Number",
                      className:
                        "py-2 px-4 rounded-[10px] border border-[#FF8213] w-full pl-12",
                    }}
                  />

                  {errors.phoneNumber && (
                    <span className="text-red-500 text-[12px]">
                      {errors.phoneNumber}
                    </span>
                  )}
                  <span className="font-sans text-[14px] font-normal text-[#000000]">
                    By proceeding you agree to the <br />
                    <span className="text-[#FF8213]">
                      Terms & Conditions
                    </span>{" "}
                    and <span className="text-[#FF8213]">Privacy Policy</span>{" "}
                    of <span className="text-[#FF8213]">RitiVidhi</span>
                  </span>
                  {loginLoading ? (
                    <div className="w-full flex justify-center rounded-[10px] bg-[#fffffff]">
                      <img src={loading} alt="loading" className="size-12" />
                    </div>
                  ) : (
                    <button
                      className="w-full py-3 flex justify-center rounded-[10px] text-[18px] text-[#ffffff] bg-[#FF8213]"
                      onClick={handleLogin}
                    >
                      Login
                    </button>
                  )}
                  <div className="text-center">
                    <button
                      className="w-full py-2 border border-gray-300 rounded-full flex items-center justify-center space-x-2 text-gray-600 hover:bg-orange-500 hover:text-[#ffffff]"
                      onClick={handlegoogle}
                    >
                      <img src={google} alt="Google" className="size-5" />
                      <span>Continue with Google</span>
                    </button>
                  </div>
                </>
              )}

              <div className="font-sans text-[14px] font-normal text-[#000000]">
                Create an account?{" "}
                <span
                  className="text-[#FF8213] cursor-pointer"
                  onClick={handleSignupChange}
                >
                  Sign Up
                </span>
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        <Modal
          open={openloginmodal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          disableEnforceFocus
          className="bg-[#00000099]"
        >
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[400px] bg-white border-2 border-[#FF8213CC] shadow-xl px-8 py-6 rounded-[10px]">
            <div id="modal-modal-description" className="flex flex-col gap-4">
              <div className="w-full flex flex-row justify-between items-center">
                <LazyLoad height={20}>
                  <img
                    src={ritividhi}
                    alt="RitiVidhi logo"
                    className="size-20"
                  />
                </LazyLoad>
                <LazyLoad height={20}>
                  <img
                    src={cross}
                    alt="Close"
                    className="size-8 cursor-pointer"
                    onClick={handleClose}
                  />
                </LazyLoad>
              </div>
              {showOTP ? (
                <>
                  <h1 className="font-sans text-[20px] text-[#000000] font-bold">
                    Sign Up to create your account
                  </h1>
                  <OTPInput
                    value={OTP}
                    onChange={setOTP}
                    numInputs={6}
                    otpType="number"
                    disabled={false}
                    renderSeparator={<span className="mx-2">-</span>}
                    renderInput={(props) => (
                      <input
                        {...props}
                        className="flex-1 mx-1 text-center border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                    )}
                  />

                  <div className="w-full flex justify-center text-[20px]">
                    {count}
                  </div>

                  {errors.phoneNumber && (
                    <span className="text-red-500 text-[12px]">
                      {errors.phoneNumber}
                    </span>
                  )}
                  <span className="font-sans text-[14px] font-normal text-[#000000]">
                    By proceeding you agree to the <br />
                    <span className="text-[#FF8213]">
                      Terms & Conditions
                    </span>{" "}
                    and <span className="text-[#FF8213]">Privacy Policy</span>{" "}
                    of <span className="text-[#FF8213]">RitiVidhi</span>
                  </span>

                  {count < 1 ? (
                    <div className="flex flex-row justify-center items-center gap-4">
                      <button
                        onClick={() => handleback()}
                        className="w-full py-3 flex justify-center rounded-[10px] text-[18px] text-[#ffffff] bg-[#FF8213]"
                      >
                        Back
                      </button>
                      <button
                        onClick={() => handleResend()}
                        className="w-full py-3 flex justify-center rounded-[10px] text-[18px] text-[#ffffff] bg-[#FF8213]"
                      >
                        Resend OTP
                      </button>
                    </div>
                  ) : (
                    <div className="flex flex-row justify-center items-center gap-4">
                      <button
                        onClick={() => handleback()}
                        className="w-full py-3 flex justify-center rounded-[10px] text-[18px] text-[#ffffff] bg-[#FF8213]"
                      >
                        Back
                      </button>
                      {signupLoading ? (
                        <div className="w-full py-3 flex justify-center rounded-[10px] bg-[#fffffff]">
                          <img src={loading} alt="loading" className="size-8" />
                        </div>
                      ) : (
                        <button
                          className="w-full py-3 flex justify-center rounded-[10px] text-[18px] text-[#ffffff] bg-[#FF8213]"
                          onClick={onOTPVerify}
                        >
                          Verfiy
                        </button>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <>
                  <h1 className="font-sans text-[20px] text-[#000000] font-bold">
                    Sign Up to create your account
                  </h1>
                  <label>Full Name</label>
                  <input
                    type="text"
                    placeholder="Full Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="py-2 px-4 rounded-[10px] border border-[#FF8213]"
                  />
                  {errors.name && (
                    <span className="text-red-500 text-[12px]">
                      {errors.name}
                    </span>
                  )}
                  <PhoneInput
                    country={"in"}
                    value={phoneNumber}
                    onChange={(phoneNumber) =>
                      setPhoneNumber("+" + phoneNumber)
                    }
                    inputProps={{
                      placeholder: "Phone Number",
                      className:
                        "py-2 px-4 rounded-[10px] border border-[#FF8213] w-full pl-12",
                    }}
                  />
                  {errors.phoneNumber && (
                    <span className="text-red-500 text-[12px]">
                      {errors.phoneNumber}
                    </span>
                  )}
                  <span className="font-sans text-[14px] font-normal text-[#000000]">
                    By proceeding you agree to the <br />
                    <span className="text-[#FF8213]">
                      Terms & Conditions
                    </span>{" "}
                    and <span className="text-[#FF8213]">Privacy Policy</span>{" "}
                    of <span className="text-[#FF8213]">RitiVidhi</span>
                  </span>
                  {signupLoading ? (
                    <div className="w-full py-3 flex justify-center rounded-[10px] bg-[#fffffff]">
                      <img src={loading} alt="loading" className="size-12" />
                    </div>
                  ) : (
                    <button
                      className="w-full py-3 flex justify-center rounded-[10px] text-[18px] text-[#ffffff] bg-[#FF8213]"
                      onClick={handleSignUp}
                    >
                      Sign Up
                    </button>
                  )}
                  <div className="text-center">
                    <button
                      className="w-full py-2 border border-gray-300 rounded-full flex items-center justify-center space-x-2 text-gray-600 hover:bg-orange-500 hover:text-[#ffffff]"
                      onClick={handlegoogle}
                    >
                      <img src={google} alt="Google" className="size-5" />
                      <span>Continue with Google</span>
                    </button>
                  </div>
                </>
              )}

              <div className="font-sans text-[14px] font-normal text-[#000000]">
                Already have an account?{" "}
                <span
                  className="text-[#FF8213] cursor-pointer"
                  onClick={handleSignupChange}
                >
                  Login
                </span>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default LoginAccountModal;
