import React, { useContext, useEffect } from "react";
import { ContextData } from "../../../../context/Context";
import GroupPujaAddonCard from "./section/GroupPujaAddonCard";

function GroupPujaAddon() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    currentgrouppujadetail,
    currentgrouptempledetail,
    setCurrentgrouptempledetail,
  } = useContext(ContextData);

  return (
    <>
      {currentgrouppujadetail.map((grouppuja) => {
        const { pujaId } = grouppuja;

        return (
          <div key={pujaId} className="flex flex-row max-md:flex-col">
            <div className="flex-1 overflow-auto scrollbar-hide">
              <div className="flex flex-row justify-between items-center my-6 text-[16px] max-md:text-[16px] max-sm:text-[12px] font-inter text-justify px-10">
                <span className="text-[#000000] font-semibold">Add Ons</span>
              </div>
              <GroupPujaAddonCard
                {...{
                  currentgrouptempledetail,
                  setCurrentgrouptempledetail,
                }}
              />
            </div>
          </div>
        );
      })}
    </>
  );
}

export default GroupPujaAddon;
