import React, { useContext, useEffect } from "react";
import NavBar from "../../../components/navbar/NavBar";
import Footer from "../../../components/footer/Footer";
import MahaKumbhReview from "./sections/MahaKumbhReview";
import MahaKumbhTravellerDetails from "./sections/MahaKumbhTravellerDetails";
import MahaKumbhPrice from "./sections/MahaKumbhPrice";
import { ContextData } from "../../../context/Context";

function MahaKumbhBookingPage() {
  const { loggedIn, setOpenloginmodal } = useContext(ContextData);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavBar />
      <div className="flex flex-row max-md:flex-col px-[8%] gap-6">
        <div className={loggedIn ? "w-2/3 max-md:w-full" : "w-full"}>
          <MahaKumbhReview />
          <div className={loggedIn ? "block" : "hidden"}>
            <MahaKumbhTravellerDetails />
          </div>
          <div className={loggedIn ? "hidden" : "block"}>
            <div className="w-full flex justify-center">
              <button
                className="bg-[#850101] px-12 py-3 text-[#ffffff] rounded-[20px] text-[16px] my-10 w-fit"
                onClick={() => setOpenloginmodal(true)}
              >
                Log In to Continue
              </button>
            </div>
          </div>
        </div>
        <div
          className={
            loggedIn ? "h-full w-1/3 max-md:w-full sticky top-0" : "hidden"
          }
        >
          <MahaKumbhPrice />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default MahaKumbhBookingPage;
