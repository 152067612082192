import React from "react";

function CartDataCard({ finalcartpujadata }) {
  const pujaImage =
    finalcartpujadata.pujaImageURL && finalcartpujadata.pujaImageURL[0]
      ? finalcartpujadata.pujaImageURL[0].toLowerCase()
      : null;
  let imageURL;

  if (pujaImage) {
    const pujaimagearray = pujaImage.split("/");
    imageURL = `https://${pujaimagearray[2]}.s3.amazonaws.com/${pujaimagearray[3]}/${pujaimagearray[4]}/${pujaimagearray[5]}`;
  } else {
    imageURL = "";
  }
  return (
    <div className="border rounded-[10px] flex flex-row justify-between items-center bg-[#fff1cf] max-md:flex-col">
      <div className="flex flex-row gap-4 items-center max-sm:flex-col">
        <img
          src={imageURL}
          alt={finalcartpujadata.name}
          className="size-48 rounded-[10px] max-sm:mt-5"
        />
        <div className="flex flex-col gap-2 text-[16px] font-inter font-bold text-[#FF8213] py-4">
          <div className="text-[18px] font-inter font-semibold text-[#000000]">
            Name:{" "}
            <span className=" font-bold text-[#FF8213]">
              {finalcartpujadata.name}
            </span>
          </div>
          <div>
            <span className="font-semibold text-[#000000]">Mode:</span>{" "}
            {finalcartpujadata.mode}
          </div>
          <div className="mb-2">
            <span className="font-semibold text-[#000000]">Package Name:</span>{" "}
            {finalcartpujadata.homePuja.name[0]}
          </div>
        </div>
      </div>
      <div className="text-[18px] font-inter font-semibold text-[#000000] pr-10 py-4">
        Price :{" "}
        <span className="font-bold text-[#FF8213]">
          ₹ {finalcartpujadata.totalPrice}
        </span>
      </div>
    </div>
  );
}

export default CartDataCard;
