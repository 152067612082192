import React, { useContext, useEffect } from "react";
import PackageSelection from "./sections/PackageSelection";
import { ContextData } from "../../../../context/Context";
import Button from "../../../../components/button/Button";
import { message } from "antd";
import FooterSmall from "../../../../components/footer/FooterSmall";
import Mode from "./sections/Mode";
import Footer from "../../../../components/footer/Footer";
import PujaSamagri from "./sections/PujaSamagri";
import toast, { Toaster } from "react-hot-toast";

function ProductInfo() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    pujasteps,
    currentpuja,
    nextstep,
    prevstep,
    currentpujadetail,
    selectedmode,
    selectedpackage,
    setPujaSummaryCopy,
    pujasummary,
    pujadescriptionreadmore,
    setpujadescriptionreadmore,
    pujasignificancereadmore,
    setpujasignificancereadmore,
    sethavanreadmore,
    setpujasreadmore,
    setyajmanreadmore,
    setselectedmode,
    setselectedpackage,
  } = useContext(ContextData);

  useEffect(() => {
    setPujaSummaryCopy(pujasummary);
  }, [setPujaSummaryCopy, pujasummary]);

  const handlenext = () => {
    if (selectedmode && selectedpackage) {
      nextstep();
    } else {
      toast.error("Please select both a mode and a package to proceed.");
    }
  };

  const handleprev = () => {
    prevstep();
    setpujadescriptionreadmore(true);
    setpujasignificancereadmore(true);
    sethavanreadmore(true);
    setpujasreadmore(true);
    setyajmanreadmore(true);
    setselectedmode("");
    setselectedpackage("");
  };

  return (
    <>
      {currentpujadetail && currentpujadetail.length !== 0 ? (
        <>
          {currentpujadetail.map((product) => {
            const {
              pujaId,
              name,
              pujaImageURL,
              description,
              mode,
              homePuja,
              samagri,
              significance,
            } = product;

            let allpackages = homePuja.packages;

            const pujaImage = pujaImageURL[0].toLowerCase();
            const pujaimagearray = pujaImage.split("/");
            const imageURL = `https://${pujaimagearray[2]}.s3.amazonaws.com/${pujaimagearray[3]}/${pujaimagearray[4]}/${pujaimagearray[5]}`;

            return (
              <div
                key={pujaId}
                className="flex flex-row max-md:gap-0 max-md:flex-col"
              >
                <Toaster position="top-center" reverseOrder={false} />
                <div className="flex-1">
                  <img
                    src={imageURL}
                    alt={name}
                    className="w-full h-screen border-t-4 border-r-4 border-[#795EE3] max-md:border-none max-sm:h-full"
                  />
                </div>
                <div className="flex-1 h-screen overflow-auto scrollbar-hide">
                  <div className="mt-5 px-10">
                    <Button
                      label="Previous"
                      style={{
                        backgroundColor: "transparent",
                        color: "black",
                        borderColor: "#FF8213",
                      }}
                      onClick={handleprev}
                    />
                  </div>
                  <div className="mt-5 px-10">
                    <span className="font-inter text-[#000000] text-[32px] max-md:text-[28px] font-bold">
                      {name}
                    </span>
                  </div>
                  <hr className="bg-[#795EE3] h-1 w-full my-4" />
                  <div className="text-[#000000] text-inter text-justify flex flex-col gap-4 mb-8 px-10">
                    <span className="text-[24px] font-bold">
                      Puja Description
                    </span>
                    <div>
                      <p
                        className={
                          pujadescriptionreadmore
                            ? "text-[16px] font-normal h-[4.5rem] overflow-hidden"
                            : "text-[16px] font-normal"
                        }
                      >
                        {description}
                      </p>
                      {pujadescriptionreadmore ? (
                        <button
                          onClick={() =>
                            setpujadescriptionreadmore(!pujadescriptionreadmore)
                          }
                          className="text-[#FF8213]"
                        >
                          Read more
                        </button>
                      ) : (
                        <button
                          onClick={() =>
                            setpujadescriptionreadmore(!pujadescriptionreadmore)
                          }
                          className="text-[#FF8213]"
                        >
                          Read less
                        </button>
                      )}
                    </div>

                    <span className="text-[24px] font-bold">Significance</span>
                    <div>
                      <p
                        className={
                          pujasignificancereadmore
                            ? "text-[16px] font-normal h-[4.5rem] overflow-hidden"
                            : "text-[16px] font-normal"
                        }
                      >
                        {significance}
                      </p>
                      {pujasignificancereadmore ? (
                        <button
                          onClick={() =>
                            setpujasignificancereadmore(
                              !pujasignificancereadmore
                            )
                          }
                          className="text-[#FF8213]"
                        >
                          Read more
                        </button>
                      ) : (
                        <button
                          onClick={() =>
                            setpujasignificancereadmore(
                              !pujasignificancereadmore
                            )
                          }
                          className="text-[#FF8213]"
                        >
                          Read less
                        </button>
                      )}
                    </div>
                  </div>

                  <div className="text-[#000000] text-inter text-justify flex flex-col gap-4 mb-8 px-10">
                    <span className="text-[24px] font-bold">Select Mode</span>
                    <div>
                      <Mode {...{ pujaId, mode }} />
                    </div>
                  </div>

                  <div
                    className={`text-[#000000] text-inter text-justify flex flex-col gap-4 mb-8 px-10 ${
                      selectedmode ? "block" : "hidden"
                    }`}
                  >
                    <span className="text-[24px] font-bold">
                      Select Package
                    </span>
                    <div>
                      <PackageSelection {...{ pujaId, allpackages, name }} />
                    </div>
                  </div>

                  <div className="text-[#000000] text-inter text-justify flex flex-col gap-4 mb-8 px-10">
                    <span className="text-[24px] font-bold">Puja Samagri</span>
                    <PujaSamagri {...{ samagri }} />
                  </div>

                  <div className="flex flex-row justify-around items-center py-10 px-10">
                    {currentpuja > 0 && (
                      <Button
                        label="Previous"
                        style={{
                          backgroundColor: "#FBD16E",
                          color: "black",
                          borderColor: "#FF8213",
                        }}
                        onClick={() => prevstep()}
                      />
                    )}
                    {currentpuja < pujasteps.length - 1 && (
                      <Button
                        label="Next"
                        style={{
                          backgroundColor: "#FBD16E",
                          color: "black",
                          borderColor: "#FF8213",
                        }}
                        onClick={() => handlenext()}
                      />
                    )}
                    {currentpuja === pujasteps.length - 1 && (
                      <Button
                        label="Done"
                        style={{
                          backgroundColor: "#FBD16E",
                          color: "black",
                          borderColor: "#FF8213",
                        }}
                        onClick={() => message.success("Processing complete!")}
                      />
                    )}
                  </div>
                  <div className="block max-sm:hidden">
                    <FooterSmall />
                  </div>
                  <div className="hidden max-sm:block">
                    <Footer />
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <div className="h-full">
          <div className="font-inter text-[#000000] text-[28px] max-md:text-[24px] font-bold flex w-full h-80 items-center justify-center">
            Loading your Puja...
          </div>
          <Footer />
        </div>
      )}
    </>
  );
}

export default ProductInfo;
