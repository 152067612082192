import React, { useContext, useEffect, useRef } from "react";
import Button from "../../../components/button/Button";
import Footer from "../../../components/footer/Footer";
import { ContextData } from "../../../context/Context";
import { Link, useParams } from "react-router-dom";
import arrowleftblog from "../../../assets/arrowleftblog.svg";
import arrowrightblog from "../../../assets/arrowrightblog.svg";
import NavBar from "../../../components/navbar/NavBar";

function BlogView() {
  const { id } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { posts } = useContext(ContextData);

  const scrollContainerRef = useRef(null);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };
  const post = posts.find((post) => post.id.toString() === id);

  return (
    <>
      <NavBar />
      {post ? (
        // Render the selected article
        <div className="mx-[8%] my-10">
          <div className="mb-4">
            <h2 className="text-3xl font-semibold text-gray-800">
              {post.title.rendered}
            </h2>
            <p className="text-sm text-gray-500 mt-2">
              Published on: {new Date(post.date).toLocaleDateString()}
            </p>
          </div>
          <div className=" p-6 rounded-xl  border-2">
            <div
              dangerouslySetInnerHTML={{ __html: post.content.rendered }}
              className="mt-1 text-gray-700"
            />
          </div>
        </div>
      ) : (
        <div className="mx-[8%] my-10">
          <h2 className="text-xl font-bold">Loding...</h2>
        </div>
      )}

      {/* Carousel Section */}
      <div className="mx-[8%] my-10 relative">
        <img
          onClick={scrollLeft}
          className="absolute left-0 top-1/2 transform -translate-y-1/2 text-white p-2 rounded-full cursor-pointer bg-[#ffffff]"
          src={arrowleftblog}
          alt="Scroll Left"
        />
        <div
          className="flex flex-row  gap-8 overflow-x-scroll whitespace-nowrap scrollbar-hide "
          ref={scrollContainerRef}
        >
          {posts.map((blog) => (
            <Link to={`/blogview/${blog.id}`} key={blog.id}>
              <div className="flex flex-col gap-0 w-60 ">
                <img
                  src={blog.guid.rendered}
                  alt={blog.title.rendered}
                  className="rounded-t-[10px]"
                  loading="lazy"
                />
                <span className="w-full flex justify-center bg-[#FFD981] py-1 px-4 rounded-b-[10px] truncate text-center text-sm font-medium">
                  {blog.title.rendered}
                </span>
              </div>
            </Link>
          ))}
          <div className="flex   items-center w-full">
            <Link to="/blog" className="mr-16">
              <Button
                label="View All"
                style={{
                  backgroundColor: "transparent",
                  color: "black",
                  borderColor: "#000000",
                }}
              />
            </Link>
          </div>
        </div>
        <img
          onClick={scrollRight}
          className="absolute right-0 top-1/2 transform -translate-y-1/2 text-white p-2 rounded-full cursor-pointer bg-[#ffffff]"
          src={arrowrightblog}
          alt="Scroll Right"
        />
      </div>
      <Footer />
    </>
  );
}

export default BlogView;
