import React, { useContext, useEffect, useState } from "react";
import namaste from "../../assets/namaste.png";
import profilepic from "../../assets/profilepic.png";
import email from "../../assets/email.svg";
import phone from "../../assets/phone.svg";
import order from "../../assets/order.svg";
import UserProfileForm from "../../components/profileform/UserProfileForm";
import Footer from "../../components/footer/Footer";
import { ContextData } from "../../context/Context";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import logout from "../../assets/logout.svg";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase/setup";
import NavBar from "../../components/navbar/NavBar";
import axios from "axios";
import { useNavigate } from "react-router";

function UserProfile() {
  let navigate = useNavigate();

  const { setLoggedIn } = useContext(ContextData);

  const token = localStorage.getItem("token");

  const [username, setusername] = useState("");
  const [useremail, setuseremail] = useState("");
  const [usernumber, setusernumber] = useState("");

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        setLoggedIn(false);
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };

  useEffect(() => {
    if (token) {
      handlegetuser();
    } else {
      navigate("/");
      window.location.reload();
    }
  }, []);

  const handlegetuser = async () => {
    try {
      const res = await axios({
        method: "get",
        url: "https://app.ritividhi.com/ritividhi/users/currentUser",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setusername(res.data.name);
      setusernumber(res.data.phone);
      setuseremail(res.data.email);
    } catch (error) {
      setLoggedIn(false);
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      window.location.reload();
    }
  };

  return (
    <>
      <NavBar />
      <div className="px-[8%] pb-10 flex flex-col gap-10 mb-20">
        <Breadcrumb
          items={[
            {
              title: (
                <a
                  href="/"
                  className="font-inter font-medium text-[14px] text-[#000000]"
                >
                  Home
                </a>
              ),
            },
            {
              title: (
                <a
                  href="/user"
                  className="font-inter font-medium text-[14px] text-[#000000]"
                >
                  Profile
                </a>
              ),
            },
          ]}
          className="mt-5"
        />
        <div className="w-full flex justify-center">
          <img src={namaste} alt="namaste" />
        </div>
        <div className="flex flex-row max-lg:flex-col max-lg:items-start max-md:items-center justify-between items-end gap-6 -mt-32 max-md:-mt-20">
          <div className="w-full flex flex-row max-sm:flex-col max-sm:items-center items-end gap-4">
            <img
              src={profilepic}
              alt="profilepic"
              className="size-40 rounded-full"
            />
            <div className="flex flex-col items-start max-sm:items-center gap-4">
              <span
                className={
                  username
                    ? "font-inter text-[28px] text-[#000000] font-bold"
                    : "hidden"
                }
              >
                {username}
              </span>
              <div className="flex flex-row max-md:flex-col max-md:items-start max-sm:items-center items-center max-sm:w-full gap-8">
                <div
                  className={
                    useremail ? "flex flex-row items-center gap-4" : "hidden"
                  }
                >
                  <img src={email} alt="email" className="size-6" />
                  <span className="font-inter text-[18px] text-[#000000] font-semibold">
                    {useremail}
                  </span>
                </div>
                <div
                  className={
                    usernumber ? "flex flex-row items-center gap-4" : "hidden"
                  }
                >
                  <img src={phone} alt="phone" className="size-6" />
                  <span className="font-inter text-[18px] text-[#000000] font-semibold">
                    {usernumber}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start max-sm:w-full max-sm:items-center gap-4">
            <Link to="/order">
              <div className="flex flex-row items-center gap-4">
                <img src={order} alt="order" />
                <span className="font-inter text-[16px] text-[#000000] font-normal no-wrap shrink-0">
                  Order History/ Updates
                </span>
              </div>
            </Link>
            <Link to="/" onClick={handleSignOut}>
              <div className="flex flex-row items-center gap-4">
                <img src={logout} alt="logout" className="size-8" />
                <span className="font-inter text-[16px] text-[#000000] font-normal no-wrap shrink-0">
                  Log Out
                </span>
              </div>
            </Link>
          </div>
        </div>
        {/*<UserProfileForm />*/}
      </div>
      <Footer />
    </>
  );
}

export default UserProfile;
