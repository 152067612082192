import React, { useContext } from "react";
import { ContextData } from "../../../../context/Context";
import PujaSamagri from "../../../productpage/sections/productinfo/sections/PujaSamagri";
import Button from "../../../../components/button/Button";
import { message } from "antd";
import FooterSmall from "../../../../components/footer/FooterSmall";
import Footer from "../../../../components/footer/Footer";
import TemplePujaMode from "./sections/TemplePujaMode";
import TemplePujaPackageSelection from "./sections/TemplePujaPackageSelection";

function CurrentTemplePuja() {
  const {
    selectedtemplepujadetail,
    currenttemplepuja,
    templepujastep,
    nexttemplestep,
    prevtemplestep,
    selectedtemplemode,
    setTempleSummaryCopy,
    templesummary,
    pujadescriptionreadmore,
    setpujadescriptionreadmore,
    pujasignificancereadmore,
    setpujasignificancereadmore,
    sethavanreadmore,
    setpujasreadmore,
    setyajmanreadmore,
    setselectedtemplemode,
    setselectedpackage,
  } = useContext(ContextData);

  setTempleSummaryCopy(templesummary);

  const handleprev = () => {
    prevtemplestep();
    setpujadescriptionreadmore(true);
    setpujasignificancereadmore(true);
    sethavanreadmore(true);
    setpujasreadmore(true);
    setyajmanreadmore(true);
    setselectedtemplemode("");
    setselectedpackage("");
  };

  return (
    <div>
      {selectedtemplepujadetail.length > 0 && selectedtemplepujadetail ? (
        <>
          {selectedtemplepujadetail.map((selectedtemple) => {
            const {
              pujaId,
              name,
              description,
              mode,
              significance,
              homePuja,
              samagri,
              templeName,
            } = selectedtemple;

            let allpackages = homePuja.packages;

            return (
              <div
                key={pujaId}
                className="flex flex-row max-md:gap-0 max-md:flex-col"
              >
                <div className="flex-1">
                  <img
                    src=""
                    alt={name}
                    className="w-full h-screen border-t-4 border-r-4 border-[#795EE3] max-md:border-none max-sm:h-full"
                  />
                </div>
                <div className="flex-1 h-screen overflow-auto scrollbar-hide">
                  <div className="my-5 px-10">
                    <Button
                      label="Previous"
                      style={{
                        backgroundColor: "transparent",
                        color: "black",
                        borderColor: "#FF8213",
                      }}
                      onClick={handleprev}
                    />
                  </div>
                  <div className="flex flex-col justify-between mt-5 px-10">
                    <span className="font-inter text-[#000000] text-[32px] max-md:text-[28px] font-bold">
                      {name}
                    </span>
                    <span className="text-[16px] font-inter font-semibold text-[#000000]">
                      at <span className="text-[#FF8213]">{templeName}</span>
                    </span>
                  </div>
                  <hr className="bg-[#795EE3] h-1 w-full my-4" />
                  <div className="text-[#000000] text-inter text-justify flex flex-col gap-4 mb-8 px-10">
                    <span className="text-[24px] font-bold">
                      Puja Description
                    </span>
                    <div>
                      <p
                        className={
                          pujadescriptionreadmore
                            ? "text-[16px] font-normal h-[4.5rem] overflow-hidden"
                            : "text-[16px] font-normal"
                        }
                      >
                        {description}
                      </p>
                      {pujadescriptionreadmore ? (
                        <button
                          onClick={() =>
                            setpujadescriptionreadmore(!pujadescriptionreadmore)
                          }
                          className="text-[#FF8213]"
                        >
                          Read more
                        </button>
                      ) : (
                        <button
                          onClick={() =>
                            setpujadescriptionreadmore(!pujadescriptionreadmore)
                          }
                          className="text-[#FF8213]"
                        >
                          Read less
                        </button>
                      )}
                    </div>

                    <span className="text-[24px] font-bold">Significance</span>
                    <div>
                      <p
                        className={
                          pujasignificancereadmore
                            ? "text-[16px] font-normal h-[4.5rem] overflow-hidden"
                            : "text-[16px] font-normal"
                        }
                      >
                        {significance}
                      </p>
                      {pujasignificancereadmore ? (
                        <button
                          onClick={() =>
                            setpujasignificancereadmore(
                              !pujasignificancereadmore
                            )
                          }
                          className="text-[#FF8213]"
                        >
                          Read more
                        </button>
                      ) : (
                        <button
                          onClick={() =>
                            setpujasignificancereadmore(
                              !pujasignificancereadmore
                            )
                          }
                          className="text-[#FF8213]"
                        >
                          Read less
                        </button>
                      )}
                    </div>
                  </div>

                  <div className="text-[#000000] text-inter text-justify flex flex-col gap-4 mb-8 px-10">
                    <span className="text-[24px] font-bold">Select Mode</span>
                    <div>
                      <TemplePujaMode {...{ mode }} />
                    </div>
                  </div>

                  <div
                    className={`text-[#000000] text-inter text-justify flex flex-col gap-4 mb-8 px-10 ${
                      selectedtemplemode ? "block" : "hidden"
                    }`}
                  >
                    <span className="text-[24px] font-bold">
                      Select Package
                    </span>
                    <div>
                      <TemplePujaPackageSelection
                        {...{ pujaId, allpackages, name }}
                      />
                    </div>
                  </div>

                  <div className="text-[#000000] text-inter text-justify flex flex-col gap-4 mb-8 px-10">
                    <span className="text-[24px] font-bold">Puja Samagri</span>
                    <PujaSamagri {...{ samagri }} />
                  </div>

                  <div className="flex flex-row justify-around items-center pb-10 px-10">
                    {currenttemplepuja > 0 && (
                      <Button
                        label="Previous"
                        style={{
                          backgroundColor: "#FBD16E",
                          color: "black",
                          borderColor: "#FF8213",
                        }}
                        onClick={() => prevtemplestep()}
                      />
                    )}
                    {currenttemplepuja < templepujastep.length - 1 && (
                      <Button
                        label="Next"
                        style={{
                          backgroundColor: "#FBD16E",
                          color: "black",
                          borderColor: "#FF8213",
                        }}
                        onClick={() => nexttemplestep()}
                      />
                    )}
                    {currenttemplepuja === templepujastep.length - 1 && (
                      <Button
                        label="Done"
                        style={{
                          backgroundColor: "#FBD16E",
                          color: "black",
                          borderColor: "#FF8213",
                        }}
                        onClick={() => message.success("Processing complete!")}
                      />
                    )}
                  </div>
                  <div className="block max-sm:hidden">
                    <FooterSmall />
                  </div>
                  <div className="hidden max-sm:block">
                    <Footer />
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <div className="h-full">
          <div className="font-inter text-[#000000] text-[28px] max-md:text-[24px] font-bold flex w-full h-80 items-center justify-center">
            Loading your Puja...
          </div>
          <Footer />
        </div>
      )}
    </div>
  );
}

export default CurrentTemplePuja;
