import React from "react";
import LazyLoad from "react-lazyload";
import Button from "../button/Button";
import meditation from "../../assets/landingpageicons/meditation.gif";
import { Link } from "react-router-dom";

function Service6() {
  return (
    <div
      className="w-full border border-2 border-[#FFD981] flex flex-row justify-around items-center max-xl:items-start max-xl:gap-4 rounded-[20px] pr-4 max-xl:flex-col max-xl:px-10 max-xl:pb-10"
      style={{ background: "linear-gradient(90deg, #F3E9D2 57.5%, #FFF 100%)" }}
    >
      <LazyLoad height={300}>
        <div className="flex items-center justify-center h-[250px]">
          <img
            src={meditation}
            alt="grouppuja"
            className="w-[300px] h-[250px] max-xl:w-[350px] max-xl:h-[300px] scale-125 self-center"
          />
        </div>
      </LazyLoad>
      <div className="flex flex-col justify-center gap-4">
        <h1 className="text-[28px] font-sans non-italic font-bold text-[#000000]">
          Meditation (Coming Soon...)
        </h1>
        <p className="text-[16px] font-sans non-italic font-normal text-[#000000] mb-4">
          Coming Soon...
        </p>
        <Link to="/">
          <Button
            label="Book a Pooja"
            style={{
              backgroundColor: "#FFF1D0",
              color: "black",
              borderColor: "#000000",
            }}
          />
        </Link>
      </div>
    </div>
  );
}

export default Service6;
