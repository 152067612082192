import { message } from "antd";
import React, { useContext, useEffect, useRef } from "react";
import FooterSmall from "../../../../components/footer/FooterSmall";
import Footer from "../../../../components/footer/Footer";
import Button from "../../../../components/button/Button";
import ContactForm from "../../../../components/contactform/ContactForm";
import { ContextData } from "../../../../context/Context";

function TemplePujaAddressForm() {
  const {
    selectedtemplepujadetail,
    nexttemplestep,
    prevtemplestep,
    templepujastep,
    currenttemplepuja,
    carttemplepujadata,
    setCarttemplePujaData,
  } = useContext(ContextData);

  const contactFormRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleNextClick = () => {
    if (contactFormRef.current.validate()) {
      const formData = contactFormRef.current.getFormData();

      if (carttemplepujadata.length > 0) {
        const updatedaddressdata = {
          ...carttemplepujadata[0],
          deliveryAddress: formData,
        };
        setCarttemplePujaData([
          updatedaddressdata,
          ...carttemplepujadata.slice(1),
        ]);
      }

      contactFormRef.current.resetForm();
      nexttemplestep();
    } else {
      message.error("Please fill in all required fields.");
    }
  };
  return (
    <>
      {selectedtemplepujadetail.length > 0 && selectedtemplepujadetail ? (
        <>
          <div className="w-full">
            {selectedtemplepujadetail.map((currentselectedpuja) => {
              const { pujaId, name } = currentselectedpuja;
              return (
                <div
                  key={pujaId}
                  className="flex flex-row max-md:gap-0 max-md:flex-col"
                >
                  <div className="flex-1">
                    <img
                      src=""
                      alt={name}
                      className="w-full h-screen border-t-4 border-r-4 border-[#795EE3] max-md:border-none max-sm:h-full"
                    />
                  </div>
                  <div className="flex-1 h-screen overflow-auto scrollbar-hide">
                    <div className="my-5 px-10">
                      <Button
                        label="Previous"
                        style={{
                          backgroundColor: "transparent",
                          color: "black",
                          borderColor: "#FF8213",
                        }}
                        onClick={() => prevtemplestep()}
                      />
                    </div>
                    <div className="mt-5 px-10">
                      <span className="font-inter text-[#000000] text-[32px] max-md:text-[28px] font-bold w-1/2">
                        {name}
                      </span>
                    </div>
                    <hr className="bg-[#795EE3] h-1 w-full my-4" />

                    <ContactForm ref={contactFormRef} />

                    <div className="flex flex-row justify-around items-center pb-10 px-10">
                      {currenttemplepuja > 0 && (
                        <Button
                          label="Previous"
                          style={{
                            backgroundColor: "#FBD16E",
                            color: "black",
                            borderColor: "#FF8213",
                          }}
                          onClick={prevtemplestep}
                        />
                      )}
                      {currenttemplepuja < templepujastep.length - 1 && (
                        <Button
                          label="Next"
                          style={{
                            backgroundColor: "#FBD16E",
                            color: "black",
                            borderColor: "#FF8213",
                          }}
                          onClick={handleNextClick}
                        />
                      )}
                      {currenttemplepuja === templepujastep.length - 1 && (
                        <Button
                          label="Done"
                          style={{
                            backgroundColor: "#FBD16E",
                            color: "black",
                            borderColor: "#FF8213",
                          }}
                          onClick={() =>
                            message.success("Processing complete!")
                          }
                        />
                      )}
                    </div>
                    <div className="block max-sm:hidden">
                      <FooterSmall />
                    </div>
                    <div className="hidden max-sm:block">
                      <Footer />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <div className="h-full">
          <div className="font-inter text-[#000000] text-[28px] max-md:text-[24px] font-bold flex w-full h-80 items-center justify-center">
            Loading...
          </div>
          <Footer />
        </div>
      )}
    </>
  );
}

export default TemplePujaAddressForm;
