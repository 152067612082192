import React, { useContext, useEffect } from "react";
import GroupPujaCard from "../../../components/cards/GroupPujaCard";
import Footer from "../../../components/footer/Footer";
import GroupPujaMainCard from "../../../components/cards/GroupPujaMainCard";
import { ContextData } from "../../../context/Context";

function GroupPujaInfo() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { grouppujasummary } = useContext(ContextData);

  return (
    <>
      <div className="px-[8%] py-10 flex flex-col gap-16">
        <GroupPujaMainCard />
        <div className="grid grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1 gap-16">
          {grouppujasummary.map((group) => {
            const { pujaId, pujaName, aboutPuja, pujaImageUrl, liveEvents } =
              group;
            return (
              <div key={pujaId}>
                <GroupPujaCard
                  {...{
                    pujaId,
                    pujaName,
                    aboutPuja,
                    pujaImageUrl,
                    liveEvents,
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default GroupPujaInfo;
