import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContextData } from "../../../../context/Context";
import { DatePicker, message, Space, TimePicker } from "antd";
import FooterSmall from "../../../../components/footer/FooterSmall";
import Footer from "../../../../components/footer/Footer";
import moment from "moment";
import Button from "../../../../components/button/Button";
import TemplePujaAddons from "../addonstemplepuja/TemplePujaAddons";
import TemplePujaCartModal from "../../../../components/modals/TemplePujaCartModal";

function DateTimeReview() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const {
    selectedDate,
    setSelectedDate,
    selectedTime,
    setSelectedTime,
    specialRequirement,
    setSpecialRequirement,
    setOpenTemplePujacartModal,
    nexttemplestep,
    selectedtemplepujadetail,
    currenttemplepuja,
    templepujastep,
    prevtemplestep,
    carttemplepujadata,
    setCarttemplePujaData,
    selectedcopytemplepujadetail,
    setTemplePujaTotal,
  } = useContext(ContextData);

  useEffect(() => {
    if (!carttemplepujadata || carttemplepujadata.length === 0) {
      navigate("/");
    }
  }, [carttemplepujadata, navigate]);

  const updatedPuja = () => {
    const updatedCartPujaData = selectedcopytemplepujadetail.map(
      (currentselectedpuja) => {
        const { homePuja, addOns } = currentselectedpuja;
        let pujaTotal = homePuja.packagePrice || 0;
        addOns.forEach((add) => {
          if (add.quantity > 0) {
            pujaTotal += add.newPrice || 0;
          }
        });
        return pujaTotal;
      }
    );
    setTemplePujaTotal(updatedCartPujaData);
  };

  const handleNext = () => {
    if (!selectedDate) {
      message.error("Please select a date.");
      return;
    }
    if (!selectedTime) {
      message.error("Please select a time.");
      return;
    }

    const updatedpujadateTime = carttemplepujadata.map((cartpujadatetime) => {
      if (carttemplepujadata.length > 0) {
        return {
          ...cartpujadatetime,
          selectedDate: selectedDate,
          selectedTime: selectedTime,
          specialRequirement: specialRequirement,
        };
      }
      return cartpujadatetime;
    });

    const updatedAddons = selectedtemplepujadetail[0].addOns;
    updatedpujadateTime[0].pujas[0].addOns = updatedAddons;

    updatedPuja();
    updatedpujadateTime[0] = {
      ...updatedpujadateTime[0],
      pujas: selectedcopytemplepujadetail,
    };

    setCarttemplePujaData(updatedpujadateTime);
    nexttemplestep();
  };

  const handleDone = () => {
    if (!selectedDate) {
      message.error("Please select a date.");
      return;
    }
    if (!selectedTime) {
      message.error("Please select a time.");
      return;
    }

    let updatedpujadateTime = carttemplepujadata.map((cartpujadatetime) => {
      if (carttemplepujadata.length > 0) {
        return {
          ...cartpujadatetime,
          selectedDate: selectedDate,
          selectedTime: selectedTime,
          specialRequirement: specialRequirement,
        };
      }
      return cartpujadatetime;
    });

    const updatedAddons = selectedtemplepujadetail[0].addOns;
    updatedpujadateTime[0].pujas[0].addOns = updatedAddons;

    updatedPuja();
    updatedpujadateTime[0] = {
      ...updatedpujadateTime[0],
      pujas: selectedcopytemplepujadetail,
    };

    setCarttemplePujaData(updatedpujadateTime);
    setOpenTemplePujacartModal(true);
  };

  return (
    <>
      {carttemplepujadata.length > 0 && carttemplepujadata ? (
        <div className="w-full">
          {carttemplepujadata[0].pujas.map((selectedpuja) => {
            const { pujaId, name } = selectedpuja;
            return (
              <div
                key={pujaId}
                className="flex flex-row max-md:gap-0 max-md:flex-col"
              >
                <div className="flex-1">
                  <img
                    src=""
                    alt={name}
                    className="w-full h-screen border-t-4 border-r-4 border-[#795EE3] max-md:border-none max-sm:h-full"
                  />
                </div>
                <div className="flex-1 h-screen overflow-auto scrollbar-hide">
                  <div className="my-5 px-10">
                    <Button
                      label="Previous"
                      style={{
                        backgroundColor: "transparent",
                        color: "black",
                        borderColor: "#FF8213",
                      }}
                      onClick={() => prevtemplestep()}
                    />
                  </div>
                  <div className="mt-5 px-10">
                    <span className="font-inter text-[#000000] text-[32px] max-md:text-[28px] font-bold w-1/2">
                      {name}
                    </span>
                  </div>
                  <hr className="bg-[#795EE3] h-1 w-full my-4" />

                  <div className="flex flex-col text-[20px] max-md:text-[16px] text-[#000000] font-inter font-bold text-justify gap-4 px-10">
                    <span>Date and Time Preferences</span>
                    <span className="font-medium text-[16px] max-md:text-[12px]">
                      Please let us know your preferable date and time?
                    </span>
                  </div>

                  <div className="flex flex-row gap-8 items-center px-10">
                    <Space direction="vertical" className="my-10">
                      <DatePicker
                        onChange={(date) => setSelectedDate(date)}
                        size="large"
                        disabledDate={(current) =>
                          current &&
                          (current < moment().add(4, "days").startOf("day") ||
                            current > moment().add(1, "year").endOf("day"))
                        }
                      />
                    </Space>

                    <Space wrap>
                      <TimePicker
                        use12Hours
                        format="h:mm a"
                        onChange={(time) => setSelectedTime(time)}
                        size="large"
                      />
                    </Space>
                  </div>

                  <div className="flex flex-col text-[20px] max-md:text-[16px] font-inter font-bold text-justify font-medium gap-4 mb-10 px-10">
                    <span>Any special Requirement? (Optional)</span>
                    <span className="font-medium text-[16px] max-md:text-[12px]">
                      If you are having any specific or special requirement
                      please feel free to let us know
                    </span>
                    <textarea
                      className="rounded-[10px] bg-[#FFDBBB] p-2"
                      value={specialRequirement}
                      onChange={(e) => setSpecialRequirement(e.target.value)}
                    ></textarea>
                  </div>

                  <TemplePujaAddons />

                  <div className="flex flex-row justify-around items-center pb-10 px-10">
                    {currenttemplepuja > 0 && (
                      <Button
                        label="Previous"
                        style={{
                          backgroundColor: "#FBD16E",
                          color: "black",
                          borderColor: "#FF8213",
                        }}
                        onClick={() => prevtemplestep()}
                      />
                    )}
                    {currenttemplepuja < templepujastep.length - 1 && (
                      <Button
                        label="Next"
                        style={{
                          backgroundColor: "#FBD16E",
                          color: "black",
                          borderColor: "#FF8213",
                        }}
                        onClick={handleNext}
                      />
                    )}
                    {currenttemplepuja === templepujastep.length - 1 && (
                      <Button
                        label="Review"
                        style={{
                          backgroundColor: "#FBD16E",
                          color: "black",
                          borderColor: "#FF8213",
                        }}
                        onClick={handleDone}
                      />
                    )}
                  </div>
                  <div className="block max-sm:hidden">
                    <FooterSmall />
                  </div>
                  <div className="hidden max-sm:block">
                    <Footer />
                  </div>
                </div>
              </div>
            );
          })}
          <TemplePujaCartModal cartpujadata={carttemplepujadata} />
        </div>
      ) : (
        <>
          <div className="h-full">
            <div className="font-inter text-[#000000] text-[28px] max-md:text-[24px] font-bold flex w-full h-80 items-center justify-center">
              Loading...
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
}

export default DateTimeReview;
