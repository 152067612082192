import React from "react";
import { Link, useLocation } from "react-router-dom";

function Navigation() {
  const location = useLocation();

  return (
    <div className="flex flex-col gap-4 font-sans text-[#00000] text-[20px] max-lg:text-[16px] font-normal non-italic">
      <h1 className="text-[22px] max-lg:text-[18px] font-bold">Navigations</h1>
      <Link
        to="/"
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        style={{ cursor: "pointer" }}
      >
        Home
      </Link>
      {location.pathname === "/" && (
        <a href="#about">
          <button>About us</button>
        </a>
      )}
      {location.pathname === "/" && (
        <a href="#allpujas">
          <button>Pujas</button>
        </a>
      )}
      <a href="https://wa.me/+919910738229">
        <button>Contact us</button>
      </a>
    </div>
  );
}

export default Navigation;
