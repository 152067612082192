import React, { useContext } from "react";
import allpujaimage2 from "../../../../assets/disposableassets/allpujaimage2.png";
import LazyLoad from "react-lazyload";
import Button from "../../../../components/button/Button";
import packageskumbh from "../../../../assets/packageskumbh.svg";
import omkumbh from "../../../../assets/omkumbh.svg";
import groupkumbh from "../../../../assets/groupkumbh.svg";
import onlinegroupkumbh from "../../../../assets/onlinegroupkumbh.svg";
import helpkumbh from "../../../../assets/helpkumbh.svg";
import { Link } from "react-router-dom";
import { ContextData } from "../../../../context/Context";
import stay from "../../../../assets/mahakumbh/stay.svg";
import travel from "../../../../assets/mahakumbh/travel.svg";
import meal from "../../../../assets/mahakumbh/meal.svg";
import tag from "../../../../assets/mahakumbh/tag.svg";

function MahaKumbhPackageslist() {
  const {
    mahakumbhdata,
    setMahaKumbhPackageData,
    setMahaKumbhOrderData,
    setMahaKumbhCalendermodal,
    setMahaKumbhRoomModal,
    setMahaKumbhSelectedDate,
    setMahaKumbhAdultQuantity,
    setmahakumbhroomtotal,
    setMahaKumbhRoomQuantity,
    setConfirm,
  } = useContext(ContextData);

  const handlemahakumbhpackage = async (packageId) => {
    try {
      const response = await fetch(
        `https://app.ritividhi.com/ritividhi/mahakumbh2025/${packageId}`
      );
      const packagedata = await response.json();

      setMahaKumbhPackageData(packagedata);
      const dataToStore = {
        packageId: packagedata.packageId,
      };

      setMahaKumbhOrderData(dataToStore);
      setMahaKumbhCalendermodal(false);
      setMahaKumbhRoomModal(false);
      setMahaKumbhSelectedDate(null);
      setMahaKumbhAdultQuantity(2);
      setmahakumbhroomtotal(0);
      setMahaKumbhRoomQuantity(1);
      setConfirm(false);
      localStorage.setItem("mahakumbhorder", JSON.stringify(dataToStore));
      localStorage.setItem("mahakumbhpackage", JSON.stringify(packagedata));
    } catch (error) {
      console.error("Error fetching all mahakumbh data", error);
    }
  };

  return (
    <div className="px-[8%]">
      {/*<div className="flex flex-row w-full justify-center gap-10 mb-16">
        <div className="flex flex-col items-center gap-4">
          <img src={packageskumbh} alt="packageskumbh" className="size-8" />
          <span className="font-semibold text-[16px] font-sans text-[#850101] max-sm:hidden">
            Kumbh Packages
          </span>
        </div>

        <div className="flex flex-col items-center gap-4">
          <img src={omkumbh} alt="omkumbh" className="size-8" />
          <span className="font-semibold text-[16px] font-sans text-[#850101] max-sm:hidden">
            Temple Puja
          </span>
        </div>

        <div className="flex flex-col items-center gap-4">
          <img src={groupkumbh} alt="groupkumbh" className="size-8" />
          <span className="font-semibold text-[16px] font-sans text-[#850101] max-sm:hidden">
            Group Puja
          </span>
        </div>

        <div className="flex flex-col items-center gap-4">
          <img
            src={onlinegroupkumbh}
            alt="onlinegroupkumbh"
            className="size-8"
          />
          <span className="font-semibold text-[16px] font-sans text-[#850101] max-sm:hidden">
            Group Puja
          </span>
        </div>

        <div className="flex flex-col items-center gap-4">
          <img src={helpkumbh} alt="helpkumbh" className="size-8" />
          <span className="font-semibold text-[16px] font-sans text-[#850101] max-sm:hidden">
            Need Help ?
          </span>
        </div>
      </div>*/}
      <div className="grid grid-cols-3 gap-8 max-lg:grid-cols-2 max-sm:grid-cols-1">
        {mahakumbhdata.map((puja) => {
          const { packageId, packageName, durationDays, pricing } = puja;

          return (
            <Link to="/mahakumbhpackage" key={packageId}>
              <div
                className="border border-2 border-[#B8F3DE] flex flex-col justify-between max-sm:gap-0 rounded-[20px] h-full cursor-pointer"
                style={{
                  background:
                    "linear-gradient(180deg, #FFF 20.5%, #EAF5F1 43.5%)",
                }}
              >
                <div className="relative">
                  <LazyLoad height={100} offset={100}>
                    <img
                      src={allpujaimage2}
                      alt={packageName}
                      className="rounded-t-[20px]"
                    />
                  </LazyLoad>
                  <div className="absolute top-5 left-5 flex flex-row items-center gap-2 text-[#ffffff] text-[14px] max-md:text-[12px] bg-gradient-to-r from-[#ff9800] via-[#ff5757] to-[#ff9800] bg-[length:400%_400%] animate-gradient px-4 py-2 rounded-[20px]  font-sans font-bold">
                    <img src={tag} alt="tag" className="size-6 max-md:size-4" />
                    <span>Recommended</span>
                  </div>
                  <h1 className="absolute bottom-5 right-5 text-[#ffffff] text-[14px] max-md:text-[10px] font-sans font-bold bg-[#000000BF] p-2 rounded-[20px]">
                    {durationDays[0].totalDays}
                  </h1>
                </div>

                <div className="p-4 flex flex-col justify-between h-full gap-2 mb-4">
                  <h1 className="text-[20px] max-md:text-[16px] max-sm:text-[12px] font-sans non-italic font-bold text-[#850101]">
                    {packageName}
                  </h1>
                  <div className="flex flex-col gap-2">
                    <span className="text-[12px] font-sans non-italic font-bold text-[#850101]">
                      {durationDays[0].days.toString().toUpperCase()}
                    </span>
                    <hr className="h-[1px] w-full bg-[#A9A9A9]" />
                    <div className="flex flex-row gap-4 text-[12px] font-sans non-italic font-semibold text-[#850101]">
                      <div className="flex flex-col items-center">
                        <img src={stay} alt="stay" className="size-8" />
                        <span>Hotel</span>
                      </div>
                      <div className="flex flex-col  items-center">
                        <img src={travel} alt="travel" className="size-8" />
                        <span>Transfer</span>
                      </div>
                      <div className="flex flex-col items-center">
                        <img src={meal} alt="meal" className="size-8" />
                        <span>Meal</span>
                      </div>
                    </div>
                    <hr className="h-[1px] w-full bg-[#A9A9A9] mb-4" />
                    <div className="flex flex-row justify-between items-center">
                      <div className="flex flex-col text-[18px] font-sans non-italic font-semibold text-[#850101]">
                        <span className="text-[12px]">Starting from</span>
                        <div>₹ {pricing.basePrice}</div>
                      </div>
                      <Button
                        label="Book Now"
                        style={{
                          backgroundColor: "#fff1d0",
                          color: "#850101",
                          borderColor: "#850101",
                        }}
                        onClick={() => handlemahakumbhpackage(packageId)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default MahaKumbhPackageslist;
