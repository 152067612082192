import React from "react";

function TermsCondition() {
  const terms = [
    {
      id: 1,
      heading: "Booking Confirmation",
      points: [
        {
          point:
            "All bookings are subject to availability and will be confirmed only upon receipt of full payment.",
        },
      ],
    },
    {
      id: 2,
      heading: "Cancellation and Refund Policy",
      points: [
        {
          point:
            "Cancellation requests must be made at least 7 days prior to the scheduled date for a full refund.",
        },
        {
          point:
            "Cancellations made within 7 days of the event will incur a 50% charge.",
        },
        {
          point:
            "No refunds will be provided for cancellations made within 48 hours of the event.",
        },
      ],
    },
    {
      id: 3,
      heading: "Rescheduling",
      points: [
        {
          point:
            "Rescheduling is allowed once per booking and must be requested at least 5 days prior to the scheduled date.",
        },
      ],
    },
    {
      id: 4,
      heading: "Puja Samagri and Rituals",
      points: [
        {
          point:
            "RitiVidhi ensures the use of authentic and high-quality puja samagri.",
        },
        {
          point: "Rituals will be conducted strictly as per Vedic guidelines.",
        },
      ],
    },
    {
      id: 5,
      heading: "Pandit Services",
      points: [
        {
          point:
            "The pandits provided by RitiVidhi are highly trained in Vedic rituals and their decisions during the ceremony must be respected.",
        },
      ],
    },
    {
      id: 6,
      heading: "Code of Conduct",
      points: [
        {
          point:
            "Smoking, alcohol, or any intoxicants are strictly prohibited during the rituals.",
        },
        {
          point:
            "Attendees are expected to maintain decorum and follow instructions provided by the pandits or RitiVidhi coordinators.",
        },
      ],
    },
    {
      id: 7,
      heading: "Liability",
      points: [
        {
          point:
            "RitiVidhi is not responsible for any personal injuries, loss of belongings, or unforeseen circumstances during the rituals.",
        },
      ],
    },
    {
      id: 8,
      heading: "Privacy Policy",
      points: [
        {
          point:
            "Personal information provided during booking will only be used for the purpose of organizing and conducting the rituals.",
        },
        {
          point:
            "Data will not be shared with any third party without consent.",
        },
      ],
    },
    {
      id: 9,
      heading: "Changes to the Terms",
      points: [
        {
          point:
            "RitiVidhi reserves the right to update or modify these terms and conditions at any time without prior notice.",
        },
      ],
    },
    {
      id: 10,
      heading: "Agreement",
      points: [
        {
          point:
            "By booking with RitiVidhi, you agree to abide by these terms and conditions.",
        },
      ],
    },
  ];

  return (
    <div className="px-[8%] py-10 flex flex-col gap-8">
      <h1 className="font-sans text-[24px] max-sm:text-[18px] text-[#850101] font-bold mt-10">
        Terms and Conditions for RitiVidhi
      </h1>
      <div>
        {terms.map((term) => {
          const { id, heading, points } = term;
          return (
            <div key={id}>
              <ol>
                <li className="text-[18px] max-sm:text-[16px] font-bold text-[#850101] font-inter my-5">
                  {id}.{"  "}
                  {heading}
                </li>
              </ol>
              <ul className="list-disc list-inside">
                {points.map((termspoint, index) => (
                  <li
                    key={index}
                    className="text-[18px] max-sm:text-[16px] font-normal text-[#850101] font-inter my-5 mx-5"
                  >
                    {termspoint.point}
                  </li>
                ))}
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default TermsCondition;
