import React from "react";

function AboutGroupPuja({ pujaName, aboutPuja }) {
  return (
    <div className="px-[8%] pt-24 max-md:pt-36 max-sm:pt-20">
      <h1 className="text-[20px] font-bold font-inter text-[#00000] mb-10">
        {pujaName}
      </h1>
      <div className="text-[18px] font-normal font-inter text-[#00000]">
        {aboutPuja}
      </div>
    </div>
  );
}

export default AboutGroupPuja;
