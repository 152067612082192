import React, { useContext } from "react";
import dot from "../../../../assets/dot.svg";
import { ContextData } from "../../../../context/Context";

function MahaKumbhReview() {
  const { mahakumbhpackagedata } = useContext(ContextData);

  let formattedDate = "";
  let formattednextDate = "";

  if (mahakumbhpackagedata.selectedStartDate) {
    const extractedDate = mahakumbhpackagedata.selectedStartDate?.$d;

    if (extractedDate) {
      formattedDate = extractedDate.toISOString().split("T")[0];

      const newDate = new Date(extractedDate);
      newDate.setDate(
        newDate.getDate() +
          mahakumbhpackagedata.durationDays[0].totalDaysAsInt +
          1
      );
      formattednextDate = newDate.toISOString().split("T")[0];
    } else {
      console.error("extractedDate is undefined");
    }
  }

  return (
    <>
      {mahakumbhpackagedata && mahakumbhpackagedata.length !== 0 ? (
        <>
          <div className="py-5">
            <div className="border border-[#000000] shadow-customloginsign rounded-[10px]">
              <div className="bg-[#edf3fb] px-[4%] py-5 rounded-t-[10px] font-sans text-[24px] text-[#850101] font-bold">
                Package Detail
              </div>
              <div className="flex flex-row gap-2 py-5 px-[8%] bg-[#ffffff] rounded-t-[10px]">
                <div>
                  <img src="" alt="" />
                </div>
                <div className="flex flex-col gap-2">
                  <h1 className="font-sans text-[32px] max-sm:text-[22px] max-md:text-[26px] text-[#850101] font-bold">
                    {mahakumbhpackagedata.packageName}
                  </h1>
                  <span className="font-sans text-[14px] text-[#850101] font-bold">
                    Duration:{" "}
                    <span className="font-semibold">
                      {mahakumbhpackagedata.durationDays[0].days}
                    </span>
                  </span>
                  <span className="font-sans text-[14px] text-[#850101] font-bold">
                    No. of Person(s) :{" "}
                    <span className="font-semibold">
                      {mahakumbhpackagedata.totalNumberOfAdults < 2
                        ? `${mahakumbhpackagedata.totalNumberOfAdults} Adult`
                        : `${mahakumbhpackagedata.totalNumberOfAdults} Adults`}{" "}
                    </span>
                  </span>
                  <div className="flex flex-row gap-2">
                    <span className="font-sans text-[14px] text-[#850101] font-bold">
                      Start Date :{" "}
                      <span className="font-semibold">{formattedDate}</span>
                    </span>
                    <span className="font-sans text-[14px] text-[#850101] font-bold">
                      End Date :
                      <span className="font-semibold">{formattednextDate}</span>
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div className="bg-[#edf3fb] flex flex-col gap-2 px-[4%] py-5">
                  <span className="font-sans text-[14px] text-[#850101] font-bold">
                    Accommodation
                  </span>
                  <div className="flex flex-row flex-wrap gap-1">
                    {mahakumbhpackagedata.incl_exlu.accommodation.map(
                      (accommodation, index) => {
                        return (
                          <div
                            key={index}
                            className="flex flex-row items-center"
                          >
                            <img src={dot} alt="dot" className="size-6" />
                            <span className="font-sans text-[14px] text-[#850101] font-semibold">
                              {accommodation}
                            </span>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>

                <hr className="h-[1px] w-full bg-[#A9A9A9]" />
                <div className="bg-[#edf3fb] flex flex-col gap-2 px-[4%] py-5">
                  <span className="font-sans text-[14px] text-[#850101] font-bold">
                    Activities
                  </span>
                  <div className="flex flex-row flex-wrap gap-1">
                    {mahakumbhpackagedata.incl_exlu.activities.map(
                      (activities, index) => {
                        return (
                          <div
                            key={index}
                            className="flex flex-row items-center"
                          >
                            <img src={dot} alt="dot" className="size-6" />
                            <span className="font-sans text-[14px] text-[#850101] font-semibold">
                              {activities}
                            </span>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
                <hr className="h-[1px] w-full bg-[#A9A9A9]" />
                <div className="bg-[#edf3fb] flex flex-col gap-2 px-[4%] py-5">
                  <span className="font-sans text-[14px] text-[#850101] font-bold">
                    Amenities
                  </span>
                  <div className="flex flex-row flex-wrap gap-1">
                    {mahakumbhpackagedata.incl_exlu.amenities.map(
                      (amenities, index) => {
                        return (
                          <div
                            key={index}
                            className="flex flex-row items-center"
                          >
                            <img src={dot} alt="dot" className="size-6" />
                            <span className="font-sans text-[14px] text-[#850101] font-semibold">
                              {amenities}
                            </span>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
                <hr className="h-[1px] w-full bg-[#A9A9A9]" />
                <div className="bg-[#edf3fb] flex flex-col gap-2 px-[4%] py-5">
                  <span className="font-sans text-[14px] text-[#850101] font-bold">
                    Exclusions
                  </span>
                  <div className="flex flex-row flex-wrap gap-1">
                    {mahakumbhpackagedata.incl_exlu.exclusions.map(
                      (exclusions, index) => {
                        return (
                          <div
                            key={index}
                            className="flex flex-row items-center"
                          >
                            <img src={dot} alt="dot" className="size-6" />
                            <span className="font-sans text-[14px] text-[#850101] font-semibold">
                              {exclusions}
                            </span>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
                <hr className="h-[1px] w-full bg-[#A9A9A9]" />
                <div className="bg-[#edf3fb] flex flex-col gap-2 px-[4%] py-5">
                  <span className="font-sans text-[14px] text-[#850101] font-bold">
                    Meals
                  </span>
                  <div className="flex flex-row flex-wrap gap-1">
                    {mahakumbhpackagedata.incl_exlu.meals.map(
                      (meals, index) => {
                        return (
                          <div
                            key={index}
                            className="flex flex-row items-center"
                          >
                            <img src={dot} alt="dot" className="size-6" />
                            <span className="font-sans text-[14px] text-[#850101] font-semibold">
                              {meals}
                            </span>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
                <hr className="h-[1px] w-full bg-[#A9A9A9]" />
                <div className="bg-[#edf3fb] flex flex-col gap-2 px-[4%] py-5 rounded-b-[10px]">
                  <span className="font-sans text-[14px] text-[#850101] font-bold">
                    Transportation
                  </span>
                  <div className="flex flex-row flex-wrap gap-1">
                    {mahakumbhpackagedata.incl_exlu.transportation.map(
                      (transportation, index) => {
                        return (
                          <div
                            key={index}
                            className="flex flex-row items-center"
                          >
                            <img src={dot} alt="dot" className="size-6" />
                            <span className="font-sans text-[14px] text-[#850101] font-semibold">
                              {transportation}
                            </span>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="h-full">
          <div className="font-inter text-[#000000] text-[28px] max-md:text-[24px] font-bold flex w-full h-80 items-center justify-center">
            Loading your data...
          </div>
        </div>
      )}
    </>
  );
}

export default MahaKumbhReview;
