import React, { useContext, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { ContextData } from "../../context/Context.jsx";

function TemplePujaProgressSteps() {
  const { templepujastep, currenttemplepuja } = useContext(ContextData);
  const stepsRef = useRef(null);

  useEffect(() => {
    if (stepsRef.current) {
      const currentStepElement =
        stepsRef.current.querySelectorAll(".MuiStep-root")[currenttemplepuja];
      if (currentStepElement) {
        currentStepElement.scrollIntoView({
          behavior: "smooth",
          inline: "center",
        });
      }
    }
  }, [currenttemplepuja]);

  return (
    <>
      <div className="w-screen">
        <Box
          className="px-[8%] pb-2 pt-4 max-sm:px-0"
          sx={{ backgroundColor: "#F2E9FF" }}
          ref={stepsRef}
        >
          <Stepper activeStep={currenttemplepuja} alternativeLabel>
            {templepujastep.map((step, index) => (
              <Step key={index}>
                <StepLabel>
                  <span className="text-[12px] text-[#000000] font-normal font-roboto leading-0 m-0 p-0">
                    {step.title}
                  </span>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <div>{templepujastep[currenttemplepuja].content}</div>
      </div>
    </>
  );
}

export default TemplePujaProgressSteps;
