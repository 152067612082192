import React, { useEffect } from "react";
import NavBar from "../../../components/navbar/NavBar";
import Footer from "../../../components/footer/Footer";
import PackageMahaKumbhDetails from "./packagedetails/PackageMahaKumbhDetails";

function MahaKumbhPackage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavBar />
      <PackageMahaKumbhDetails />
      <Footer />
    </>
  );
}

export default MahaKumbhPackage;
