import React from "react";
import ritividhilogo from "../../assets/ritividhilogo.png";
import facebook from "../../assets/facebook.svg";
import instagram from "../../assets/instagram.svg";
import pinterest from "../../assets/pinterest.svg";
import linkedin from "../../assets/linkedin.svg";
import youtube from "../../assets/youtube.svg";
import Button from "../button/Button";
import LazyLoad from "react-lazyload";

function FooterSmall() {
  return (
    <div className="px-[8%] mt-10 py-10 flex flex-col justify-between gap-8 bg-[#E2D1F9]">
      <div className="flex flex-row gap-8">
        <div className="flex flex-col justify-between flex-1">
          <div className="flex flex-col items-center max-xl:flex-col gap-4 max-lg:gap-5">
            <img src={ritividhilogo} alt="ritividhilogo" className="size-20" />
            <span className="text-[#000000] text-[20px] font-normal non-italic font-sans flex flex-wrap">
              आचार्यानुसारं पूजनम्,गृहद्वारम् एव।
            </span>
          </div>
          <div className="flex flex-col gap-4">
            <h1 className="text-[20px] font-semibold text-[#000000] non-italic font-sans">
              Follow us on:
            </h1>

            <div className="flex flex-row flex-wrap gap-4">
              <a
                href="https://www.facebook.com/profile.php?viewas=100000686899395&id=61561276946747"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LazyLoad height={50}>
                  <img src={facebook} alt="facebook" className="size-8" />
                </LazyLoad>
              </a>
              <a
                href="https://www.instagram.com/ritividhi?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                target="_blank"
                rel="noopener noreferrer"
              >
                <LazyLoad height={50}>
                  <img src={instagram} alt="instagram" className="size-8" />
                </LazyLoad>
              </a>
              <LazyLoad height={50}>
                <img src={pinterest} alt="pinterest" className="size-8" />
              </LazyLoad>
              <a
                href="https://www.linkedin.com/company/ritividhi"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LazyLoad height={50}>
                  <img src={linkedin} alt="linkedin" className="size-8" />
                </LazyLoad>
              </a>
              <LazyLoad height={50}>
                <img src={youtube} alt="youtube" className="size-8" />
              </LazyLoad>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-8 flex-1">
          <div className="flex flex-col gap-2">
            <h1 className="text-[16px] font-bold font-sans text-[#000000] non-italic">
              Address
            </h1>
            <p className="text-[16px] font-normal font-sans text-[#000000] non-italic mb-8">
              A258, Chhatarpur Enclave Phase 2, Chattarpur Enclave, Chhatarpur,
              Delhi, New Delhi, Delhi 110074
            </p>
            <div className="text-[16px] font-normal font-sans text-[#000000] non-italic">
              <span className="font-bold">Phone:</span> +91 9910738229
            </div>
            <div className="text-[16px] font-normal font-sans text-[#000000] non-italic">
              <span className="font-bold">Email:</span> Contact@ritividhi.com
            </div>
          </div>
          <div className="flex flex-col gap-4 items-center">
            <div className="border-b-[1px] border-[#000000] py-2 px-4">
              <input
                type="text"
                placeholder="Please enter your email here"
                className="w-full py-2 bg-transparent placeholder-text-[#595959] placeholder-text-[24px] placeholder-font-sans placeholder-not-italic placeholder-font-semibold focus:outline-none"
              />
            </div>
            <Button
              label="Subscribe"
              style={{
                backgroundColor: "#FFF1D0",
                color: "black",
                borderColor: "#000000",
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between items-center gap-4 font-sans text-[#00000] text-[16px] font-normal non-italic">
        <h1 className="text-[20px] font-bold">Policies:</h1>
        <div className="flex gap-4">
          <span>Privacy Policy</span>
          <span>Refund Policy</span>
          <span>Terms and Conditions</span>
        </div>
      </div>
    </div>
  );
}

export default FooterSmall;
