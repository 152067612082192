import React, { useContext } from "react";
import { Collapse } from "antd";
import { ContextData } from "../../../../../context/Context";

function FAQ() {
  const { faqs } = useContext(ContextData);
  return (
    <div className="mx-[8%] p-8 mt-24 mb-10 max-md:mt-36 max-sm:mt-20 bg-[#ffffff] rounded-[20px]">
      <h1 className="text-[20px] font-bold font-inter text-[#00000] mb-5">
        Frequently asked Questions
      </h1>
      <Collapse accordion items={faqs} />
    </div>
  );
}

export default FAQ;
