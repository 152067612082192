import React, { useEffect } from "react";
import minusaddon from "../../../../../assets/minusaddon.svg";
import plusaddon from "../../../../../assets/plusaddon.svg";
import { useNavigate } from "react-router-dom";

function GroupPujaAddonCard({
  currentgrouptempledetail,
  setCurrentgrouptempledetail,
}) {
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentgrouptempledetail || currentgrouptempledetail.length === 0) {
      navigate("/");
    }
  }, [currentgrouptempledetail, navigate]);

  const updateAddonsquantity = (id, value) => {
    if (value < 0) return;

    const updatedPujaDetail = currentgrouptempledetail.map((pujaDetail) => {
      const updatedAddons = pujaDetail.addOns.map((add) => {
        if (add.addonId === id) {
          const newPrice = add.price * value;
          return {
            ...add,
            quantity: value,
            newPrice,
          };
        }
        return add;
      });
      return { ...pujaDetail, addOns: updatedAddons };
    });

    const totalPrice = updatedPujaDetail.reduce((total, pujaDetail) => {
      const addOnsTotal = pujaDetail.addOns.reduce(
        (sum, add) => sum + (add.newPrice || add.price * add.quantity),
        0
      );
      return total + addOnsTotal;
    }, 0);

    const updatedpriceandaddons = () => {
      return updatedPujaDetail.map((addonsprice) => ({
        ...addonsprice,
        totalPrice: totalPrice,
      }));
    };

    const updatedWithTotalPrice = updatedpriceandaddons();
    setCurrentgrouptempledetail(updatedWithTotalPrice);
  };

  return (
    <div className="flex flex-col justify-between gap-4 mb-10 w-full px-10">
      {currentgrouptempledetail &&
      currentgrouptempledetail[0]?.addOns?.length > 0 ? (
        currentgrouptempledetail[0].addOns.map((add) => {
          const { addonId, name, description, price, quantity } = add;
          return (
            <div
              key={addonId}
              className="flex flex-row justify-between max-sm:flex-col max-md:gap-4 w-full"
            >
              <div className="flex flex-col justify-between border-2 border-[#795EE3] px-4 py-3 rounded-[10px] gap-4 bg-[#E2D1F9] text-[#000000] text-[16px] max-md:text-[12px] font-inter font-bold w-full">
                <div className="flex flex-row justify-between">
                  <span>{name}</span>
                  <span>₹ {price}</span>
                </div>
                <div className="font-medium">{description}</div>
              </div>
              <div className="flex flex-row justify-end max-md:justify-around gap-10 items-center w-full">
                <div className="flex flex-row items-center gap-2">
                  <img
                    src={minusaddon}
                    alt="minusaddon"
                    className="size-8 max-md:size-6"
                    onClick={() => updateAddonsquantity(addonId, quantity - 1)}
                    style={{ cursor: quantity > 0 ? "pointer" : "not-allowed" }}
                  />
                  <img
                    src={plusaddon}
                    alt="plusaddon"
                    className="size-8 max-md:size-6"
                    onClick={() => updateAddonsquantity(addonId, quantity + 1)}
                  />
                </div>
                <span className="text-[22px] max-md:text-[16px] font-medium font-inter text-[#000000] text-justify">
                  {quantity}
                </span>
              </div>
            </div>
          );
        })
      ) : (
        <div className="text-center font-medium text-[16px] max-md:text-[12px] text-[#000000]">
          No add-ons available for this temple.
        </div>
      )}
    </div>
  );
}

export default GroupPujaAddonCard;
