import React, { useContext } from "react";
import { ContextData } from "../../context/Context";
import { Modal } from "@mui/material";
import cross from "../../assets/cross.svg";

function MahaKumbhQuery() {
  const { mahakumbhquerynmodal, setMahaKumbhQuerymodal } =
    useContext(ContextData);

  const handleClose = () => {
    setMahaKumbhQuerymodal(false);
  };

  return (
    <Modal
      open={mahakumbhquerynmodal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEnforceFocus
      className="bg-[#00000099]"
    >
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-2/4 max-md:w-full bg-[#FCF0E0] border-2 border-[#FF8213CC] shadow-xl px-8 py-5 rounded-[10px]">
        <div
          id="modal-modal-description"
          sx={{ mt: 2 }}
          className="max-sm:gap-4 w-full p-4 max-sm:p-0"
        >
          <div className="flex flex-col gap-2">
            <div className="w-full flex flex-row justify-between gap-2">
              <h1 className="text-[#000000] text-[26px] max-md:text-[22px] font-sans font-bold">
                Dive into the Spiritual Essence of <br />
                <span className="text-[#ff8213]">Maha Kumbh 2025</span>
              </h1>
              <img
                src={cross}
                alt="cross"
                className="size-8 cursor-pointer mt-5"
                onClick={handleClose}
              />
            </div>
            <hr className="h-[1px] w-full bg-[#A9A9A9]" />
            <div className="flex flex-col gap-4">
              <span className="font-inter text-[20px] max-md:text-[20px] text-[#000000] font-bold">
                Full Name
              </span>
              <div className="flex flex-row justify-around gap-4 border border-[#000000] w-2/3 max-md:w-full p-2 rounded-[10px] focus:outline-none">
                <input
                  type="text"
                  className="bg-transparent w-full placeholder:text-[#595959] placeholder:text-[14px] placeholder:font-medium placeholder:font-inter px-4 py-1"
                  placeholder="Full name"
                />
              </div>
              <hr className="h-[1px] w-full bg-[#A9A9A9]" />
              <div className="flex flex-row gap-4">
                <div className="flex flex-col gap-2">
                  <span className="font-inter text-[20px] max-md:text-[20px] text-[#000000] font-bold">
                    Email
                  </span>
                  <div className="flex flex-row justify-around gap-4 border border-[#000000] p-2 rounded-[10px] focus:outline-none">
                    <input
                      type="text"
                      className="bg-transparent w-full placeholder:text-[#595959] placeholder:text-[14px] placeholder:font-medium placeholder:font-inter px-4 py-1"
                      placeholder="Email"
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <span className="font-inter text-[20px] max-md:text-[20px] text-[#000000] font-bold">
                    Phone Number
                  </span>
                  <div className="flex flex-row justify-around gap-4 border border-[#000000] p-2 rounded-[10px] focus:outline-none">
                    <input
                      type="text"
                      className="bg-transparent w-full placeholder:text-[#595959] placeholder:text-[14px] placeholder:font-medium placeholder:font-inter px-4 py-1"
                      placeholder="Phone Number"
                    />
                  </div>
                </div>
              </div>
              <div className="w-full flex justify-center">
                <button
                  className="w-fit bg-[#850101] px-12 max-sm:px-6 py-3 text-[#ffffff] rounded-[20px] text-[16px] mb-2"
                  onClick={() => setMahaKumbhQuerymodal(true)}
                >
                  Submit your Query
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default MahaKumbhQuery;
