import React, { useContext } from "react";
import { ContextData } from "../../../../context/Context";
import axios from "axios";

function MahaKumbhPrice() {
  const { mahakumbhorderdata, userid, mahakumbhpackagedata, confirm } =
    useContext(ContextData);

  let isMahaKumbh = true;

  const handleorder = async () => {
    let token = localStorage.getItem("token");
    try {
      const res = await axios({
        method: "post",
        url: "https://app.ritividhi.com/ritividhi/kumbhOrder/createOrder",
        data: { ...mahakumbhorderdata },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      let merchantUserId = userid;
      let orderId = res.data.orderId;
      let amount = res.data.totalPrice;
      handlepayment(merchantUserId, orderId, amount, isMahaKumbh);
      console.log(res);
    } catch (error) {
      console.error("Error occurred during the request", error);
    }
  };

  const handlepayment = async (
    merchantUserId,
    orderId,
    amount,
    isMahaKumbh
  ) => {
    try {
      const res = await axios({
        method: "post",
        url: "https://app.ritividhi.com/ritividhi/payment/pay",
        data: { merchantUserId, orderId, amount, isMahaKumbh },
      });

      if (res.data?.data?.instrumentResponse?.redirectInfo?.url) {
        window.location.href =
          res.data.data.instrumentResponse.redirectInfo.url;
      } else {
        console.error("Redirect URL not found in the response.");
      }
    } catch (error) {
      console.error("Error occurred during the request", error);
    }
  };

  return (
    <>
      {mahakumbhpackagedata && mahakumbhpackagedata.length !== 0 ? (
        <>
          <div className="py-5 w-full">
            <div className="border border-[#000000] shadow-customloginsign rounded-[10px] bg-[#ffffff]">
              <div className="bg-[#edf3fb] px-[4%] py-5 rounded-t-[10px] font-sans text-[24px] text-[#850101] font-bold">
                Pricing Details
              </div>
              <hr className="h-[1px] w-full bg-[#A9A9A9]" />
              <div className="px-[8%] py-5 w-full flex flex-col gap-4">
                <div className="flex flex-row justify-between gap-10 font-sans text-[16px] text-[#850101] font-bold">
                  <span>Base Price : </span>
                  <span className="whitespace-nowrap">
                    ₹ {mahakumbhpackagedata.pricing.basePrice}
                  </span>
                </div>
                {mahakumbhpackagedata.totalPrice -
                  mahakumbhpackagedata.pricing.basePrice >
                0 ? (
                  <div className="flex flex-row justify-between gap-10 font-sans text-[16px] text-[#850101] font-bold">
                    <span>Additional Devotees Cost</span>
                    <span className="whitespace-nowrap">
                      ₹{" "}
                      {mahakumbhpackagedata.totalPrice -
                        mahakumbhpackagedata.pricing.basePrice}
                    </span>
                  </div>
                ) : null}

                <hr className="h-[1px] w-full bg-[#A9A9A9]" />
                <div className="flex flex-row justify-between gap-10 font-sans text-[16px] text-[#850101] font-bold">
                  <span>Total Price : </span>
                  <span className="whitespace-nowrap">
                    ₹ {mahakumbhpackagedata.totalPrice}
                  </span>
                </div>
              </div>
            </div>
            <div className="w-full flex justify-center">
              <button
                onClick={() => handleorder()}
                className={`px-12 py-3 rounded-[10px] text-[16px] mt-10 w-fit ${
                  confirm
                    ? "bg-[#bf0101] text-[#ffffff] hover:bg-[#850117]"
                    : "bg-gray-400 text-[#ffffff] cursor-not-allowed"
                }`}
                disabled={!confirm}
              >
                Pay Now
              </button>
            </div>
          </div>
        </>
      ) : (
        <div className="h-full">
          <div className="font-inter text-[#000000] text-[28px] max-md:text-[24px] font-bold flex w-full h-80 items-center justify-center">
            Loading your Price...
          </div>
        </div>
      )}
    </>
  );
}

export default MahaKumbhPrice;
