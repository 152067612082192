import React, { useContext } from "react";
import { ContextData } from "../../../../../context/Context";
import PackageDetailModal from "../../../../../components/modals/PackageDetailModal";
import info from "../../../../../assets/info.svg";

function PackageSelection({ pujaId, allpackages, name }) {
  const {
    selectedmode,
    cartpujadata,
    setCartPujaData,
    selectedpackage,
    setselectedpackage,
    setOpenpackagemodal,
  } = useContext(ContextData);

  const handleselectedpackage = (singlepackage) => {
    const updatedcartpackage = cartpujadata.map((cartpackage) => {
      if (cartpackage.pujaId === pujaId) {
        return {
          ...cartpackage,
          homePuja: singlepackage,
          selectedPackage: singlepackage,
        };
      }
    });
    setselectedpackage(singlepackage);
    setCartPujaData(updatedcartpackage);
  };

  return (
    <>
      {selectedmode ? (
        <>
          {selectedmode === "Online" ? (
            <div className="w-full flex flex-col justify-between my-4 gap-4 cursor-pointer">
              {allpackages.map((singlepackage) => {
                const { packageId, name, packagePrice } = singlepackage;

                const isSelected = cartpujadata.some(
                  (cartpackage) =>
                    cartpackage.pujaId === pujaId &&
                    cartpackage.homePuja?.packageId === packageId
                );

                return (
                  <div
                    key={packageId}
                    className={`w-full flex flex-row justify-between max-sm:gap-5 items-center border-2 border-[#000000] text-[16px] font-semibold px-10 max-md:px-8 py-4 gap-10 rounded-[10px] ${
                      isSelected ? "bg-[#E2D1F9]" : "bg-[#faecdf] opacity-40"
                    }`}
                    onClick={() => handleselectedpackage(singlepackage)}
                  >
                    <div className="text-[20px] max-md:text-[16px] font-inter font-bold text-[#000000]">
                      {name[0]}
                    </div>
                    <div className="flex flex-row gap-4">
                      <div className="text-[20px] max-md:text-[16px] font-inter font-bold text-[#FF8213]">
                        ₹ {packagePrice}
                      </div>
                      <button
                        onClick={() => {
                          setOpenpackagemodal(true);
                        }}
                      >
                        <img src={info} alt="info" className="size-6" />
                      </button>
                    </div>
                  </div>
                );
              })}
              {selectedpackage ? (
                <PackageDetailModal {...{ selectedpackage, name }} />
              ) : null}
            </div>
          ) : (
            <div className="w-full flex flex-col justify-between my-4 gap-4 cursor-pointer">
              {allpackages.map((singlepackage) => {
                const { packageId, name, packagePrice } = singlepackage;

                const isSelected = cartpujadata.some(
                  (cartpackage) =>
                    cartpackage.pujaId === pujaId &&
                    cartpackage.homePuja?.packageId === packageId
                );

                return (
                  <div
                    key={packageId}
                    className={`w-full flex flex-row justify-between max-sm:gap-5 items-center border-2 border-[#000000] text-[16px] font-semibold px-10 max-md:px-8 py-4 gap-10 rounded-[10px] ${
                      isSelected ? "bg-[#E2D1F9]" : "bg-[#faecdf] opacity-40"
                    }`}
                    onClick={() => handleselectedpackage(singlepackage)}
                  >
                    <div className="text-[20px] max-md:text-[16px] font-inter font-bold text-[#000000]">
                      {name[0]}
                    </div>
                    <div className="flex flex-row gap-4">
                      <div className="text-[20px] max-md:text-[16px] font-inter font-bold text-[#FF8213]">
                        ₹ {packagePrice}
                      </div>
                      <button
                        onClick={() => {
                          setOpenpackagemodal(true);
                        }}
                      >
                        <img src={info} alt="info" className="size-6" />
                      </button>
                    </div>
                  </div>
                );
              })}
              {selectedpackage ? (
                <PackageDetailModal {...{ selectedpackage, name }} />
              ) : null}
            </div>
          )}
        </>
      ) : null}
    </>
  );
}

export default PackageSelection;
