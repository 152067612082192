import React from "react";
import { Carousel } from "antd";
import "../../../../../App.css";

const contentStyle = {
  margin: "5px",
  textAlign: "justify",
  background: "#F9E0E0",
  border: "2px solid #850101",
  borderRadius: "20px",
};

function Itinerary({ mahakumbhpackagedata }) {
  return (
    <>
      {mahakumbhpackagedata && mahakumbhpackagedata.length !== 0 ? (
        <div className="px-[8%] py-10">
          <h1 className="font-sans text-[24px] max-sm:text-[18px] text-[#850101] font-bold my-10">
            Day wise Itinerary
          </h1>
          <Carousel className="custom-carousel">
            {mahakumbhpackagedata.itineraryDetails.map((iternary, index) => {
              const { day, title, description } = iternary;
              return (
                <div key={index} className="h-full">
                  <h3
                    style={contentStyle}
                    className="flex flex-col justify-between gap-2 px-20 pt-8 pb-16 max-md:px-16 max-sm:px-8 max-md:pt-6 max-md:py-14 max-sm:py-8 text-justify font-sans leading-8 text-[#850101]"
                  >
                    <span className="font-sans text-[20px] max-sm:text-[18px] text-[#850101] font-bold">
                      {day}
                    </span>
                    <span className="font-sans text-[18px] max-sm:text-[16px] text-[#850101] font-semibold">
                      {title}
                    </span>
                    <span className="font-sans text-[16px] max-sm:text-[14px] text-[#850101] font-normal leading-6">
                      {description}
                    </span>
                  </h3>
                </div>
              );
            })}
          </Carousel>
        </div>
      ) : (
        <div className="h-full">
          <div className="font-inter text-[#000000] text-[28px] max-md:text-[24px] font-bold flex w-full h-80 items-center justify-center">
            Loading your Itinerary...
          </div>
        </div>
      )}
    </>
  );
}
export default Itinerary;
