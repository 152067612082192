import React, { useContext } from "react";
import { ContextData } from "../../../../../context/Context";

function TemplePujaMode({ mode }) {
  const {
    setselectedtemplemode,
    carttemplepujadata,
    setCarttemplePujaData,
    selectedtemplepujadetail,
    selectedtemplemode,
    setSelectedCopyTemplePujadetail,
  } = useContext(ContextData);

  const handlepujamode = (selectedmodeoption) => {
    const updatedmodedata = selectedtemplepujadetail.map((pujadetail) => ({
      ...pujadetail,
      mode: selectedmodeoption,
    }));

    if (carttemplepujadata.length > 0) {
      carttemplepujadata[0] = {
        ...carttemplepujadata[0],
        pujas: updatedmodedata,
      };
    }

    const updatedmode = selectedtemplepujadetail.map(
      (selectedpackagedetail) => ({
        ...selectedpackagedetail,
        mode: updatedmodedata[0].mode,
      })
    );

    setSelectedCopyTemplePujadetail(updatedmode);
    setCarttemplePujaData(carttemplepujadata);
    setselectedtemplemode(selectedmodeoption);
  };

  return (
    <div className="w-full flex flex-row max-lg:flex-col justify-between my-4 gap-4 cursor-pointer">
      {mode?.map((selectedmodeoption, index) => {
        const isSelected = selectedmodeoption === selectedtemplemode;
        return (
          <div
            key={index}
            className={`w-full flex flex-row justify-center max-xl:flex-col items-center border-2 border-[#000000] rounded-[10px] text-[16px] font-bold px-4 py-4 gap-2 cursor-pointer ${
              isSelected ? "bg-[#E2D1F9]" : "bg-[#faecdf] opacity-40"
            }`}
            onClick={() => handlepujamode(selectedmodeoption)}
          >
            <span>{selectedmodeoption}</span>
          </div>
        );
      })}
    </div>
  );
}

export default TemplePujaMode;
