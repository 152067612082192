import React, { useContext, useEffect } from "react";
import { ContextData } from "../../../../context/Context";
import { useNavigate } from "react-router-dom";
import FooterSmall from "../../../../components/footer/FooterSmall";
import Button from "../../../../components/button/Button";
import clock from "../../../../assets/clock.svg";
import Footer from "../../../../components/footer/Footer";
import GroupPujaAddon from "../grouppujaaddon/GroupPujaAddon";
import GroupPujaCartModal from "../../../../components/modals/GroupPujaCartModal";

function GroupPujaDateTime() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const {
    grouppujastep,
    currentgrouppuja,
    nextgroupstep,
    prevgroupstep,
    setOpenGroupPujacartModal,
    currentgrouppujadetail,
    cartgrouppujadata,
    currentgrouptempledetail,
    setCartGroupPujaData,
  } = useContext(ContextData);

  useEffect(() => {
    if (!currentgrouppujadetail || currentgrouppujadetail.length === 0) {
      navigate("/");
    }
  }, [currentgrouppujadetail, navigate]);

  const handleNext = () => {
    const updatedpujadateTime = cartgrouppujadata.map((cartpujadatetime) => {
      if (cartgrouppujadata.length > 0) {
        return {
          ...cartpujadatetime,
          liveEvents: currentgrouptempledetail,
        };
      }
    });
    setCartGroupPujaData(updatedpujadateTime);
    nextgroupstep();
  };

  const handleDone = () => {
    const updatedpujadateTime = cartgrouppujadata.map((cartpujadatetime) => {
      if (cartgrouppujadata.length > 0) {
        return {
          ...cartpujadatetime,
          liveEvents: currentgrouptempledetail,
        };
      }
    });
    setCartGroupPujaData(updatedpujadateTime);
    setOpenGroupPujacartModal(true);
  };

  return (
    <>
      <div className="w-full">
        {currentgrouppujadetail.map((grouppuja) => {
          const { pujaId, pujaName, pujaImageUrl } = grouppuja;

          const pujaImage = pujaImageUrl[0].toLowerCase();
          const pujaimagearray = pujaImage.split("/");
          const groupimageurl = `https://${pujaimagearray[2]}.s3.amazonaws.com/${pujaimagearray[3]}/${pujaimagearray[4]}/${pujaimagearray[5]}`;
          return (
            <div
              key={pujaId}
              className="flex flex-row max-md:gap-0 max-md:flex-col"
            >
              <div className="flex-1">
                <img
                  src=""
                  alt={pujaName}
                  className="w-full h-screen border-t-4 border-r-4 border-[#795EE3] max-md:border-none max-sm:h-full"
                />
              </div>
              <div className="flex-1 h-screen overflow-auto scrollbar-hide">
                <div className="mt-5 px-10">
                  <Button
                    label="Previous"
                    style={{
                      backgroundColor: "transparent",
                      color: "black",
                      borderColor: "#FF8213",
                    }}
                    onClick={() => prevgroupstep()}
                  />
                </div>
                <div className="flex flex-row max-lg:flex-col justify-between items-start mt-5 px-10">
                  <span className="font-inter text-[#000000] text-[26px] max-md:text-[22px] font-bold">
                    {pujaName}
                  </span>
                  <div className="flex flex-row items-center gap-4 text-[#795EE3] text-[24px] max-md:text-[16px] font-bold mt-4">
                    <img
                      src={clock}
                      alt="clock"
                      className="size-6 max-md:size-4"
                    />
                    <span>{}</span>
                  </div>
                </div>
                <hr className="bg-[#795EE3] h-1 w-full my-4" />

                <GroupPujaAddon />

                <div className="flex flex-row justify-around items-center pb-10 px-10">
                  {currentgrouppuja > 0 && (
                    <Button
                      label="Previous"
                      style={{
                        backgroundColor: "#FBD16E",
                        color: "black",
                        borderColor: "#FF8213",
                      }}
                      onClick={() => prevgroupstep()}
                    />
                  )}
                  {currentgrouppuja < grouppujastep.length - 1 && (
                    <Button
                      label="Next"
                      style={{
                        backgroundColor: "#FBD16E",
                        color: "black",
                        borderColor: "#FF8213",
                      }}
                      onClick={handleNext}
                    />
                  )}
                  {currentgrouppuja === grouppujastep.length - 1 && (
                    <Button
                      label="Review"
                      style={{
                        backgroundColor: "#FBD16E",
                        color: "black",
                        borderColor: "#FF8213",
                      }}
                      onClick={handleDone}
                    />
                  )}
                </div>
                <div className="block max-sm:hidden">
                  <FooterSmall />
                </div>
                <div className="hidden max-sm:block">
                  <Footer />
                </div>
              </div>
            </div>
          );
        })}
        <GroupPujaCartModal cartpujadata={cartgrouppujadata} />
      </div>
    </>
  );
}

export default GroupPujaDateTime;
