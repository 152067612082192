import React, { useContext, useEffect } from "react";
import { ContextData } from "../../context/Context";
import TemplePujaProgressSteps from "../../components/progresssteps/TemplePujaProgressSteps";
import SearchCategory from "./sections/category/sections/SearchCategory";
import NavBar from "../../components/navbar/NavBar";

function TemplePujaPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { currenttemplepuja } = useContext(ContextData);

  return (
    <>
      <NavBar />
      <div>{currenttemplepuja === 0 ? <SearchCategory /> : null}</div>
      <TemplePujaProgressSteps />
    </>
  );
}

export default TemplePujaPage;
