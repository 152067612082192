import React from "react";
import categorypagemainimage from "../../assets/disposableassets/categorypagemainimage.png";
import Button from "../button/Button";
import LazyLoad from "react-lazyload";
import { Link } from "react-router-dom";

function GroupPujaMainCard() {
  return (
    <div
      className="h-min border border-2 border-[#CCA7FF] flex flex-row max-md:flex-col gap-4 rounded-[20px] cursor-pointer"
      style={{
        background:
          "linear-gradient(180deg, #FFE9B4 0%, rgba(251, 209, 110, 0.30) 50%)",
      }}
    >
      <div className="flex flex-col justify-center gap-2 px-4 py-2">
        <h1 className="text-[22px] font-sans non-italic font-bold text-[#000000] text-center">
          🌟 Limited Time Offer: Book Mahakumbh Puja Today! 🌟
        </h1>
        <h2 className="text-[18px] font-sans non-italic font-bold text-[#000000] text-center">
          🙏 Unlock Divine Blessings with a 15% Discount! 
        </h2>
        <div className="flex flex-row">
          <div>
            <ul className="text-[16px] font-sans non-italic font-normal text-[#000000] mb-2 block max-sm:hidden">
              Experience the grandeur of Mahakumbh Puja with authentic rituals
              performed by trained Vedic pandits.
              <li className="font-semibold">
                ✅ Flat 15% Off on all bookings.{" "}
              </li>
              <li className="font-semibold">
                ✅ Bonus Gift: Get a free astrology consultation.{" "}
              </li>
              <li className="font-semibold">
                ✅ Tailored Rituals for your spiritual journey.
              </li>
            </ul>
            <div>
              📅 Offer valid until{" "}
              <span className="font-semibold">February 26, 2025.</span>
            </div>
            <div>📲 Book Now to make your sacred moments truly special</div>
          </div>
          <div className="self-end max-lg:self-start max-md:self-end">
            <Link to="/kumbh">
              <Button
                label="Book Now"
                style={{
                  backgroundColor: "#FBD16E",
                  color: "black",
                  borderColor: "#FF8213",
                }}
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <LazyLoad offset={100}>
          <img
            src={categorypagemainimage}
            alt="Visual representation of Group Puja"
            className="w-[550px] h-[300px] rounded-[16px] max-md:w-auto max-md:h-auto max-md:rounded-l-[0px] max-md:rounded-b-[16px]"
          />
        </LazyLoad>
      </div>
    </div>
  );
}

export default GroupPujaMainCard;
