import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Layout from "./Layout";
import LandingPage from "./pages/landingPage/LandingPage";
import BlogPage from "./pages/blogPage/BlogPage";
import BlogView from "./pages/blogPage/sections/BlogView";
import Context from "./context/Context";
import ProductPage from "./pages/productpage/ProductPage";
import UserProfile from "./pages/userprofile/UserProfile";
import GroupPujaPage from "./pages/grouppuja/GroupPujaPage";
import OrderHistory from "./pages/userprofile/sections/orderhistory/OrderHistory";
import OrderDetail from "./pages/userprofile/sections/orderdetail/OrderDetail";
import TemplePujaPage from "./pages/templepuja/TemplePujaPage";
import AstrologyPage from "./pages/astrology/AstrologyPage";
import CartPage from "./pages/cartpage/CartPage";
import MahakumbhPage from "./pages/mahakumbh/mahakumbhmainpage/MahakumbhPage";
import MahaKumbhPackage from "./pages/mahakumbh/mahakumbhpackage/MahaKumbhPackage";
import MahaKumbhBookingPage from "./pages/mahakumbh/mahakumbhbooking/MahaKumbhBookingPage";
import { Toaster } from "react-hot-toast";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index element={<LandingPage />} />
      <Route path="blog" element={<BlogPage />} />
      <Route path="/blogview/:id" element={<BlogView />} />
      <Route path="product" element={<ProductPage />} />
      <Route path="user" element={<UserProfile />} />
      <Route path="group" element={<GroupPujaPage />} />
      <Route path="temple" element={<TemplePujaPage />} />
      <Route path="order" element={<OrderHistory />} />
      <Route path="orderdetail" element={<OrderDetail />} />
      <Route path="astrology" element={<AstrologyPage />} />
      <Route path="cart" element={<CartPage />} />
      <Route path="kumbh" element={<MahakumbhPage />} />
      <Route path="mahakumbhpackage" element={<MahaKumbhPackage />} />
      <Route path="mahakumbhbooking" element={<MahaKumbhBookingPage />} />
    </Route>
  )
);

function App() {
  return (
    <Context>
      <Toaster position="bottom-center" toastOptions={{ duration: 2000 }} />
      <RouterProvider router={router}></RouterProvider>
    </Context>
  );
}

export default App;
