import React, { useContext, useEffect, useState } from "react";
import { ContextData } from "../../../../context/Context";
import toast from "react-hot-toast";

function MahaKumbhTravellerDetails() {
  const {
    mahakumbhpackagedata,
    setMahaKumbhPackageData,
    mahakumbhorderdata,
    setMahaKumbhOrderData,
    userid,
    mahakumbhRoomtotal,
    formValid,
    setFormValid,
    setConfirm,
  } = useContext(ContextData);

  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);

  const [traveller, setTraveller] = useState({
    title: "",
    firstName: "",
    lastName: "",
  });

  const [errors, setErrors] = useState({});

  const validateInputs = () => {
    const newErrors = {};

    if (!traveller.title) newErrors.title = "Title is required.";
    if (!traveller.firstName.trim()) {
      newErrors.firstName = "First Name is required.";
    } else if (!/^[a-zA-Z]+$/.test(traveller.firstName)) {
      newErrors.firstName = "First Name must contain only letters.";
    }
    if (!traveller.lastName.trim()) {
      newErrors.lastName = "Last Name is required.";
    } else if (!/^[a-zA-Z]+$/.test(traveller.lastName)) {
      newErrors.lastName = "Last Name must contain only letters.";
    }
    if (!email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      newErrors.email = "Enter a valid email address.";
    }
    if (!mobileNumber.trim()) {
      newErrors.mobileNumber = "Mobile Number is required.";
    } else if (!/^\d{10}$/.test(mobileNumber)) {
      newErrors.mobileNumber = "Enter a valid 10-digit mobile number.";
    }
    if (!termsAccepted) {
      newErrors.terms = "You must accept the Terms & Conditions.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (field, value) => {
    setTraveller((prevTraveller) => ({
      ...prevTraveller,
      [field]: value,
    }));
  };

  const handleConfirm = () => {
    if (!validateInputs()) return;

    const updatedPackageData = {
      ...mahakumbhpackagedata,
      totalPrice: (mahakumbhpackagedata.totalPrice || 0) + mahakumbhRoomtotal,
      userId: userid,
      travelDetails: traveller,
      contactNo: mobileNumber,
      emailId: email,
      status: null,
      paymentMethod: null,
      paymentStatus: null,
    };

    localStorage.setItem(
      "mahakumbhpackage",
      JSON.stringify(updatedPackageData)
    );
    setMahaKumbhPackageData(updatedPackageData);

    const updatedOrderData = {
      ...mahakumbhorderdata,
      totalPrice: (mahakumbhpackagedata.totalPrice || 0) + mahakumbhRoomtotal,
      userId: userid,
      travelDetails: traveller,
      contactNo: mobileNumber,
      emailId: email,
      status: null,
      paymentMethod: null,
      paymentStatus: null,
    };

    localStorage.setItem("mahakumbhorder", JSON.stringify(updatedOrderData));

    toast("Devotee Details successfully added", {
      icon: "✅",
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
      },
    });
    setMahaKumbhOrderData(updatedOrderData);
    setConfirm(true);
  };

  const checkFormValidity = () => {
    return (
      traveller.title &&
      traveller.firstName &&
      traveller.lastName &&
      email &&
      mobileNumber &&
      termsAccepted
    );
  };

  useEffect(() => {
    setFormValid(checkFormValidity());
  }, [traveller, email, mobileNumber, termsAccepted]);

  return (
    <div className="py-5 w-full">
      <div className="border border-[#000000] shadow-customloginsign rounded-[10px] bg-[#ffffff]">
        <div className="bg-[#edf3fb] px-[4%] py-5 rounded-t-[10px] font-sans text-[24px] text-[#850101] font-bold">
          Traveller Details
        </div>

        <div className="flex flex-row flex-wrap gap-8 max-sm:gap-4 px-[8%] py-5">
          <div className="flex flex-col gap-2">
            <label
              htmlFor="title"
              className="font-sans text-[18px] text-[#850101] font-semibold"
            >
              Title
            </label>
            <select
              id="title"
              value={traveller.title}
              onChange={(e) => handleInputChange("title", e.target.value)}
              style={{
                padding: "0.25rem 4rem 0.25rem 1rem",
                border: "2px solid #000",
                borderRadius: "10px",
              }}
            >
              <option value="">Select Title</option>
              <option value="mr">Mr</option>
              <option value="ms">Ms</option>
              <option value="mrs">Mrs</option>
              <option value="miss">Miss</option>
            </select>
            {errors.title && (
              <span className="text-red-500">{errors.title}</span>
            )}
          </div>

          <div className="flex flex-col gap-2">
            <label className="font-sans text-[18px] text-[#850101] font-semibold">
              First Name
            </label>
            <input
              type="text"
              className="py-[0.25rem] px-4 border-2 border-[#000000] rounded-[10px]"
              placeholder="Enter First Name"
              value={traveller.firstName}
              onChange={(e) => handleInputChange("firstName", e.target.value)}
            />
            {errors.firstName && (
              <span className="text-red-500">{errors.firstName}</span>
            )}
          </div>

          <div className="flex flex-col gap-2">
            <label className="font-sans text-[18px] text-[#850101] font-semibold">
              Last Name
            </label>
            <input
              type="text"
              className="py-[0.25rem] px-4 border-2 border-[#000000] rounded-[10px]"
              placeholder="Enter Last Name"
              value={traveller.lastName}
              onChange={(e) => handleInputChange("lastName", e.target.value)}
            />
            {errors.lastName && (
              <span className="text-red-500">{errors.lastName}</span>
            )}
          </div>
        </div>

        <hr className="h-[1px] w-full bg-[#A9A9A9]" />

        <div className="flex flex-row gap-16 px-[8%] py-5">
          <div className="flex flex-col gap-2">
            <label className="font-sans text-[18px] text-[#850101] font-semibold">
              Contact Details
            </label>
            <div className="flex flex-row flex-wrap gap-8 max-sm:gap-4">
              <input
                type="text"
                className="py-[0.25rem] px-4 border-2 border-[#000000] rounded-[10px]"
                placeholder="Enter your Email ID"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errors.email && (
                <span className="text-red-500">{errors.email}</span>
              )}
              <input
                type="text"
                className="py-[0.25rem] px-4 border-2 border-[#000000] rounded-[10px]"
                placeholder="Mobile Number"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
              />
              {errors.mobileNumber && (
                <span className="text-red-500">{errors.mobileNumber}</span>
              )}
            </div>
          </div>
        </div>

        <div className="px-[4%] flex items-center gap-2 mb-5">
          <input
            type="checkbox"
            id="terms"
            checked={termsAccepted}
            onChange={(e) => setTermsAccepted(e.target.checked)}
          />
          <label htmlFor="terms">
            I understand and agree to the rules of this fare, and the Terms &
            Conditions of RitiVidhi
          </label>
        </div>
        {errors.terms && (
          <span className="text-red-500 px-[4%]">{errors.terms}</span>
        )}

        <div className="px-[4%] py-5 w-full flex justify-center bg-[#edf3fb] rounded-b-[10px]">
          <button
            onClick={handleConfirm}
            className={`px-6 py-2 rounded-[10px] ${
              formValid
                ? "bg-green-500 text-white hover:bg-green-600"
                : "bg-gray-400 text-white cursor-not-allowed"
            }`}
            disabled={!formValid}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}

export default MahaKumbhTravellerDetails;
