import React, { useContext, useEffect, useState } from "react";
import templeimage from "../../../../assets/temple.svg";
import grouptime from "../../../../assets/grouptime.svg";
import arrowright from "../../../../assets/arrowright.svg";
import GroupPujaBenefits from "./sections/GroupPujaBenefits";
import GroupPujaProcess from "./sections/GroupPujaProcess";
import TempleDescription from "./sections/TempleDescription";
import FAQ from "./sections/FAQ";
import Footer from "../../../../components/footer/Footer";
import AboutGroupPuja from "./sections/AboutGroupPuja";
import GroupPujaPackageSelection from "./sections/GroupPujaPackageSelection";
import Reviews from "./sections/Reviews";
import { ContextData } from "../../../../context/Context";
import Button from "../../../../components/button/Button";

function GroupPujaProductpage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [selectedSection, setSelectedSection] = useState("");

  const handleClick = (section) => {
    setSelectedSection(section);
  };

  const { currentgrouppujadetail, prevgroupstep } = useContext(ContextData);

  return (
    <>
      {currentgrouppujadetail.map((grouppuja) => {
        const {
          pujaId,
          pujaName,
          aboutPuja,
          pujaImageUrl,
          liveEvents,
          pujaBenefits,
          pujaProcess,
        } = grouppuja;

        const pujaImage = pujaImageUrl[0].toLowerCase();
        const pujaimagearray = pujaImage.split("/");
        const groupimageurl = `https://${pujaimagearray[2]}.s3.amazonaws.com/${pujaimagearray[3]}/${pujaimagearray[4]}/${pujaimagearray[5]}`;

        return (
          <div key={pujaId} className="flex flex-col">
            <div className="px-[8%] py-10 flex flex-row max-lg:flex-col max-lg:items-center justify-between gap-8">
              <img
                src=""
                alt={pujaName}
                className="w-[600px] h-[600px] rounded-[20px] max-md:w-[300px] max-md:h-[300px]"
              />
              <div className="flex flex-col justify-between gap-4">
                <div className="mt-5">
                  <Button
                    label="Previous"
                    style={{
                      backgroundColor: "transparent",
                      color: "black",
                      borderColor: "#FF8213",
                    }}
                    onClick={() => prevgroupstep()}
                  />
                </div>
                {/*<span className="text-[#CD110C] font-inter text-[20px] max-md:text-[16px] font-semibold underline underline-offset-8 leading-8">
                  SHAKTIPEETH KOLHAPUR AMBABAI SPECIAL
                </span>*/}
                <h1 className="text-[24px] max-md:text-[22px] font-inter font-bold text-[#000000]">
                  {pujaName}
                </h1>
                <p className="text-[20px] max-md:text-[18px] font-inter font-bold text-[#454545]">
                  {aboutPuja}
                </p>
                <div className="flex flex-row gap-4">
                  <img src={templeimage} alt="templeimage" />
                  <span className="font-sans text-[16px] max-md:text-[14px] font-normal">
                    {liveEvents[0].templeName} {liveEvents[0].templeLocation}
                  </span>
                </div>
                <div className="flex flex-row gap-4">
                  <img src={grouptime} alt="grouptime" />
                  <span className="font-sans text-[16px] max-md:text-[14px] font-normal">
                    {}
                  </span>
                </div>
                <span className="text-[18px] max-md:text-[16px] font-sans font-medium text-[#000000]">
                  Puja Booking will close in:
                </span>
                <span className="text-[18px] max-md:text-[16px] font-sans text-[#000000] font-semibold bg-[#FFB77861] w-full py-4 flex justify-center rounded-[10px] border border-1 border-[#000000]">
                  2 Days 19 Hours 54 Mins 9 Secs
                </span>
                <div className="flex flex-row gap-4 w-full py-4 flex justify-center bg-[#E6D8FA] rounded-[10px] border border-1 border-[#000000]">
                  <a href="#packages">
                    <button
                      className={`text-[18px] max-md:text-[16px] font-bold font-sans text-[#000000] ${
                        selectedSection === "packages" ? "text-[#8a2be2]" : ""
                      }`}
                      onClick={() => handleClick("packages")}
                    >
                      Select puja packages
                    </button>
                  </a>
                  <img src={arrowright} alt="arrowright" />
                </div>
              </div>
            </div>
            <div className="sticky top-0 z-10 bg-[#ffffff] flex flex-row max-sm:flex-nowrap max-md:flex-wrap justify-between gap-8 text-[16px] max-sm:text-[12px] text-[#000000] font-semibold font-inter py-5 px-[8%] border-t-2 border-b-2 border-[#d3d3d3] max-sm:overflow-x-auto max-sm:whitespace-nowrap scrollbar-hide">
              <a
                href="#about-puja"
                onClick={() => handleClick("about-puja")}
                className={`cursor-pointer ${
                  selectedSection === "about-puja" ? "text-[#9747FF]" : ""
                }`}
              >
                About Puja
              </a>
              <a
                href="#benefits"
                onClick={() => handleClick("benefits")}
                className={`cursor-pointer ${
                  selectedSection === "benefits" ? "text-[#9747FF]" : ""
                }`}
              >
                Benefits
              </a>
              <a
                href="#process"
                onClick={() => handleClick("process")}
                className={`cursor-pointer ${
                  selectedSection === "process" ? "text-[#9747FF]" : ""
                }`}
              >
                Process
              </a>
              <a
                href="#temple-details"
                onClick={() => handleClick("temple-details")}
                className={`cursor-pointer ${
                  selectedSection === "temple-details" ? "text-[#9747FF]" : ""
                }`}
              >
                Temple Details
              </a>
              <a
                href="#packages"
                onClick={() => handleClick("packages")}
                className={`cursor-pointer ${
                  selectedSection === "packages" ? "text-[#9747FF]" : ""
                }`}
              >
                Packages
              </a>
              <a
                href="#reviews"
                onClick={() => handleClick("reviews")}
                className={`cursor-pointer ${
                  selectedSection === "reviews" ? "text-[#9747FF]" : ""
                }`}
              >
                Reviews
              </a>
              <a
                href="#faqs"
                onClick={() => handleClick("faqs")}
                className={`cursor-pointer ${
                  selectedSection === "faqs" ? "text-[#9747FF]" : ""
                }`}
              >
                FAQs
              </a>
            </div>
            <div id="about-puja">
              <AboutGroupPuja {...{ pujaName, aboutPuja }} />
            </div>
            <div id="benefits">
              <GroupPujaBenefits {...{ pujaBenefits }} />
            </div>
            <div id="process">
              <GroupPujaProcess {...{ pujaProcess }} />
            </div>
            <div id="temple-details">
              <TempleDescription {...{ liveEvents }} />
            </div>
            <div id="packages">
              <GroupPujaPackageSelection grouppujapackges={liveEvents} />
            </div>
            <div id="reviews">
              <Reviews />
            </div>
            <div id="faqs">
              <FAQ />
            </div>
          </div>
        );
      })}
      <Footer />
    </>
  );
}

export default GroupPujaProductpage;
