import React, { useContext } from "react";
import MahaKumbhQuery from "../../../../components/modals/MahaKumbhQuery";
import { ContextData } from "../../../../context/Context";

function BookContact() {
  const { setMahaKumbhQuerymodal } = useContext(ContextData);

  return (
    <div className="px-[8%] py-10 w-full flex flex-col gap-8">
      <div className="flex justify-center">
        <button
          onClick={() => setMahaKumbhQuerymodal(true)}
          className="px-16 py-2 bg-[#fff1d0] rounded-full text-[#850101] border-2 border-[#850101] text-[20px] max-sm:text-[16px] font-bold font-sans"
        >
          Contact us
        </button>
      </div>
      <div className="text-center max-md:text-justify flex justify-center text-[#850101] font-semibold font-sans text-[20px] max-sm:text-[16px] max-sm:leading-6 leading-10">
        Join millions in the world's largest spiritual gathering on the sacred
        banks of <br className="block max-md:hidden" /> the Ganges. Experience
        divinity, tradition, and unity like never before
      </div>

      <MahaKumbhQuery />
    </div>
  );
}

export default BookContact;
