import React, { useContext, useState } from "react";
import { ContextData } from "../../context/Context";
import { Modal } from "@mui/material";
import { motion } from "framer-motion";
import cross from "../../assets/cross.svg";
import check from "../../assets/check.png";
import dot from "../../assets/dot.svg";
import LazyLoad from "react-lazyload";

function PackageDetailModal({ selectedpackage, name }) {
  const { openpackagenmodal, handlepackageClose } = useContext(ContextData);

  const [isExiting, setIsExiting] = useState(false);

  const handleClose = () => {
    setIsExiting(true);
    setTimeout(() => {
      handlepackageClose();
      setIsExiting(false);
    }, 500);
  };

  const modalVariants = {
    hidden: { opacity: 0, x: 300 },
    visible: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 300 },
  };

  return (
    <Modal
      open={openpackagenmodal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEnforceFocus
    >
      <motion.div
        variants={modalVariants}
        initial="hidden"
        animate={isExiting ? "exit" : "visible"}
        transition={{ duration: 0.5 }}
        className={`flex flex-col justify-between absolute w-1/2 max-lg:w-2/3 max-md:w-3/4 max-sm:w-full h-full right-0 bg-white border-2 border-[#FF8213CC] shadow-xl overflow-y-auto max-h-screen`}
      >
        <div className="px-8">
          <div className="flex flex-row justify-between items-center mt-10">
            <span className="text-[28px] font-inter font-bold text-[#000000]">
              {name}
            </span>
            <LazyLoad height={20}>
              <img
                src={cross}
                alt="cross"
                className="size-12 cursor-pointer"
                onClick={handleClose}
              />
            </LazyLoad>
          </div>
          <div className="flex flex-col gap-8">
            <div className="flex flex-row gap-4 items-center">
              <div className="text-[20px] font-inter font-bold text-[#000000]">
                {selectedpackage.name[0]}
              </div>
              <div className="text-[16px] font-inter font-bold text-[#FF8213]">
                ₹ {selectedpackage.packagePrice}
              </div>
            </div>
            <div className="flex flex-row flex-wrap gap-4">
              {selectedpackage.name.slice(1).map((selectedname, index) => {
                return (
                  <div key={index} className="flex flex-row gap-4 items-center">
                    <img src={check} alt="check" className="size-4" />
                    <div className="text-[16px] font-inter font-semibold text-[#FF8213]">
                      {selectedname}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="text-[16px] font-inter font-semibold text-[#000000]">
              {selectedpackage.packageDescription}
            </div>
            <div className="flex flex-col gap-2 bg-[#cbd5e166] p-4 rounded-[10px]">
              <div className="text-[18px] font-inter font-bold text-[#000000]">
                Procedure:
              </div>
              {selectedpackage.packageDescriptionPoints.map((points, index) => {
                return (
                  <div key={index} className="flex flex-row gap-4">
                    <img src={dot} alt="dot" className="size-6" />
                    <div className="text-[16px] font-inter font-medium text-[#000000]">
                      {points}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </motion.div>
    </Modal>
  );
}

export default PackageDetailModal;
