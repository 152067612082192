import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DatePicker, TimePicker, Space, message } from "antd";
import CartModal from "../../../components/modals/CartModal";
import { ContextData } from "../../../context/Context";
import Button from "../../../components/button/Button";
import FooterSmall from "../../../components/footer/FooterSmall";
import moment from "moment";
import Footer from "../../../components/footer/Footer";
import AddOns from "./AddOns";

function DateTime() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const {
    pujasteps,
    currentpuja,
    nextstep,
    prevstep,
    setOpencartModal,
    currentpujadetail,
    selectedDate,
    setSelectedDate,
    selectedTime,
    setSelectedTime,
    specialRequirement,
    setSpecialRequirement,
    cartpujadata,
    setCartPujaData,
  } = useContext(ContextData);

  useEffect(() => {
    if (!currentpujadetail || currentpujadetail.length === 0) {
      navigate("/");
    }
  }, [currentpujadetail, navigate]);

  const updatedPuja = () => {
    return cartpujadata.map((currentselectedpuja) => {
      const { homePuja, addOns } = currentselectedpuja;

      let pujaTotal = homePuja.packagePrice || 0;
      addOns.forEach((add) => {
        if (add.quantity > 0) {
          pujaTotal += add.newPrice || 0;
        }
      });
      return { ...currentselectedpuja, totalPrice: pujaTotal };
    });
  };

  const handledate = (date) => {
    if (date) {
      const [datePart] = date.toISOString().split("T");
      setSelectedDate(datePart);
    } else {
      setSelectedDate(null);
    }
  };

  const handletime = (time) => {
    if (time) {
      const formattedTime = time.format("HH:mm");
      setSelectedTime(formattedTime);
    } else {
      setSelectedTime(null);
    }
  };

  const handleNext = (pujaId) => {
    if (!selectedDate) {
      message.error("Please select a date.");
      return;
    }
    if (!selectedTime) {
      message.error("Please select a time.");
      return;
    }

    const updatedCart = updatedPuja();

    const updatedpujadateTime = updatedCart.map((cartpujadatetime) => {
      if (cartpujadatetime.pujaId === pujaId) {
        return {
          ...cartpujadatetime,
          selectedDate: selectedDate,
          selectedTime: selectedTime,
          specialRequirement: specialRequirement,
          status: null,
          paymentMethod: null,
          paymentStatus: null,
          panditName: null,
          pujaDate: null,
          pujaDetails: null,
          pujaType: null,
        };
      }
      return cartpujadatetime;
    });

    setCartPujaData(updatedpujadateTime);
    nextstep();
  };

  const handleDone = (pujaId) => {
    if (!selectedDate) {
      message.error("Please select a date.");
      return;
    }
    if (!selectedTime) {
      message.error("Please select a time.");
      return;
    }

    const updatedCart = updatedPuja();

    const updatedpujadateTime = updatedCart.map((cartpujadatetime) => {
      if (cartpujadatetime.pujaId === pujaId) {
        return {
          ...cartpujadatetime,
          selectedDate: selectedDate,
          selectedTime: selectedTime,
          specialRequirement: specialRequirement,
          status: null,
          paymentMethod: null,
          paymentStatus: null,
          panditName: null,
          pujaDate: null,
          pujaDetails: null,
          pujaType: null,
        };
      }
      return cartpujadatetime;
    });

    setCartPujaData(updatedpujadateTime);
    setOpencartModal(true);
  };

  return (
    <>
      <div className="w-full">
        {currentpujadetail.map((currentselectedpuja) => {
          const { pujaId, name, pujaImageURL } = currentselectedpuja;

          const pujaImage = pujaImageURL[0].toLowerCase();
          const pujaimagearray = pujaImage.split("/");
          const imageURL = `https://${pujaimagearray[2]}.s3.amazonaws.com/${pujaimagearray[3]}/${pujaimagearray[4]}/${pujaimagearray[5]}`;
          return (
            <div
              key={pujaId}
              className="flex flex-row max-md:gap-0 max-md:flex-col"
            >
              <div className="flex-1">
                <img
                  src={imageURL}
                  alt={name}
                  className="w-full h-screen border-t-4 border-r-4 border-[#795EE3] max-md:border-none max-sm:h-full"
                />
              </div>
              <div className="flex-1 h-screen overflow-auto scrollbar-hide">
                <div className="mt-5 px-10">
                  <Button
                    label="Previous"
                    style={{
                      backgroundColor: "transparent",
                      color: "black",
                      borderColor: "#FF8213",
                    }}
                    onClick={() => prevstep()}
                  />
                </div>
                <div className="mt-5 px-10">
                  <span className="font-inter text-[#000000] text-[32px] max-md:text-[28px] font-bold w-1/2">
                    {name}
                  </span>
                </div>
                <hr className="bg-[#795EE3] h-1 w-full my-4" />

                <div className="flex flex-col text-[20px] max-md:text-[16px] text-[#000000] font-inter font-bold text-justify gap-4 px-10">
                  <span>Date and Time Preferences</span>
                  <span className="font-medium text-[16px] max-md:text-[12px]">
                    Please let us know your preferable date and time?
                  </span>
                </div>

                <div className="flex flex-row gap-8 items-center px-10">
                  <Space direction="vertical" className="my-10">
                    <DatePicker
                      onChange={(date) => handledate(date)}
                      size="large"
                      disabledDate={(current) =>
                        current &&
                        (current < moment().endOf("day") ||
                          current > moment().add(1, "year").endOf("day"))
                      }
                    />
                  </Space>

                  <Space wrap>
                    <TimePicker
                      use12Hours
                      format="h:mm a"
                      onChange={(time) => handletime(time)}
                      size="large"
                    />
                  </Space>
                </div>

                <div className="flex flex-col text-[20px] max-md:text-[16px] font-inter font-bold text-justify font-medium gap-4 mb-10 px-10">
                  <span>Any special Requirement? (Optional)</span>
                  <span className="font-medium text-[16px] max-md:text-[12px]">
                    If you are having any specific or special requirement please
                    feel free to let us know
                  </span>
                  <textarea
                    className="rounded-[10px] bg-[#FFDBBB] p-2"
                    value={specialRequirement}
                    onChange={(e) => setSpecialRequirement(e.target.value)}
                  ></textarea>
                </div>

                <AddOns />

                <div className="flex flex-row justify-around items-center pb-10 px-10">
                  {currentpuja > 0 && (
                    <Button
                      label="Previous"
                      style={{
                        backgroundColor: "#FBD16E",
                        color: "black",
                        borderColor: "#FF8213",
                      }}
                      onClick={() => prevstep()}
                    />
                  )}
                  {currentpuja < pujasteps.length - 1 && (
                    <Button
                      label="Next"
                      style={{
                        backgroundColor: "#FBD16E",
                        color: "black",
                        borderColor: "#FF8213",
                      }}
                      onClick={() => handleNext(pujaId)}
                    />
                  )}
                  {currentpuja === pujasteps.length - 1 && (
                    <Button
                      label="Review"
                      style={{
                        backgroundColor: "#FBD16E",
                        color: "black",
                        borderColor: "#FF8213",
                      }}
                      onClick={() => handleDone(pujaId)}
                    />
                  )}
                </div>
                <div className="block max-sm:hidden">
                  <FooterSmall />
                </div>
                <div className="hidden max-sm:block">
                  <Footer />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <CartModal />
    </>
  );
}

export default DateTime;
