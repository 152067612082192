import React from "react";
import SearchBar from "./searchFilterComponents/SearchBar";
import Categories from "./searchFilterComponents/Categories";

function SearchFilter() {
  return (
    <div className="px-[5%] py-6 bg-[#F4ECFF] flex flex-row justify-center gap-4 sticky top-0 z-10 max-lg:hidden max-xl:flex-col max-xl:gap-4">
      {/*<SearchBar />*/}
      <Categories />
    </div>
  );
}

export default SearchFilter;
