import React, { useContext, useEffect, useState } from "react";
import PackageOverview from "../packagedetails/sections/PackageOverview";
import Pricing from "../packagedetails/sections/Pricing";
import Itinerary from "../packagedetails/sections/Itinerary";
import DoDont from "../packagedetails/sections/DoDont";
import TermsCondition from "../packagedetails/sections/TermsCondition";
import { ContextData } from "../../../../context/Context";
import InclusionExclusion from "./sections/InclusionExclusion";

function PackageMahaKumbhDetails() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { mahakumbhpackagedata } = useContext(ContextData);
  const [selectedSection, setSelectedSection] = useState("");

  const handleClick = (section) => {
    setSelectedSection(section);
  };

  return (
    <>
      {mahakumbhpackagedata && mahakumbhpackagedata.length !== 0 ? (
        <>
          <div className="px-[8%] py-10">
            <h1 className="font-sans text-[32px] max-md:text-[28px] max-sm:text-[24px] text-[#850101] font-bold">
              {mahakumbhpackagedata.packageName}
            </h1>
          </div>
          <div className="sticky top-0 z-10 flex flex-row gap-8 text-[16px] max-sm:text-[14px] text-[#808080] font-semibold font-sans py-5 my-5 px-[8%] max-sm:overflow-x-auto max-sm:whitespace-nowrap scrollbar-hide backdrop-blur-3xl">
            <a
              href="#package-overview"
              onClick={() => handleClick("package-overview")}
              className={`cursor-pointer ${
                selectedSection === "package-overview" ? "text-[#850101]" : ""
              }`}
            >
              Package Overview
            </a>
            <a
              href="#itinerary"
              onClick={() => handleClick("itinerary")}
              className={`cursor-pointer ${
                selectedSection === "itinerary" ? "text-[#850101]" : ""
              }`}
            >
              Day wise Itinerary
            </a>
            <a
              href="#inclusionexclusion"
              onClick={() => handleClick("inclusionexclusion")}
              className={`cursor-pointer ${
                selectedSection === "inclusionexclusion" ? "text-[#850101]" : ""
              }`}
            >
              Inclusion/Exclusion
            </a>
            <a
              href="#pricing"
              onClick={() => handleClick("pricing")}
              className={`cursor-pointer ${
                selectedSection === "pricing" ? "text-[#850101]" : ""
              }`}
            >
              Pricing
            </a>
            <a
              href="#do/dont"
              onClick={() => handleClick("do/dont")}
              className={`cursor-pointer ${
                selectedSection === "do/dont" ? "text-[#850101]" : ""
              }`}
            >
              Dos & Don’t
            </a>
            <a
              href="#t&c"
              onClick={() => handleClick("t&c")}
              className={`cursor-pointer ${
                selectedSection === "t&c" ? "text-[#850101]" : ""
              }`}
            >
              Term and Conditions
            </a>
          </div>
          <div id="package-overview">
            <PackageOverview {...{ mahakumbhpackagedata }} />
          </div>
          <div id="itinerary">
            <Itinerary {...{ mahakumbhpackagedata }} />
          </div>
          <div id="inclusionexclusion">
            <InclusionExclusion {...{ mahakumbhpackagedata }} />
          </div>
          <div id="pricing">
            <Pricing {...{ mahakumbhpackagedata }} />
          </div>
          <div id="do/dont">
            <DoDont />
          </div>
          <div id="t&c">
            <TermsCondition />
          </div>
        </>
      ) : (
        <div className="h-full">
          <div className="font-inter text-[#000000] text-[28px] max-md:text-[24px] font-bold flex w-full h-80 items-center justify-center">
            Loading your Puja...
          </div>
        </div>
      )}
    </>
  );
}

export default PackageMahaKumbhDetails;
