import React, { useState, forwardRef, useImperativeHandle } from "react";

const ContactForm = forwardRef((_, ref) => {
  const [formData, setFormData] = useState({
    fullName: "",
    contactNumber: "",
    addressLine1: "",
    addressLine2: "",
    landmark: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.fullName) newErrors.fullName = "Full Name is required.";
    if (!formData.contactNumber) {
      newErrors.contactNumber = "Contact Number is required.";
    } else if (!/^\d{10}$/.test(formData.contactNumber)) {
      newErrors.contactNumber = "Contact Number must be a 10-digit number.";
    }
    if (!formData.addressLine1)
      newErrors.addressLine1 = "House/Flat number is required.";
    if (!formData.addressLine2)
      newErrors.addressLine2 = "Road/Area is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const resetForm = () => {
    setFormData({
      fullName: "",
      contactNumber: "",
      addressLine1: "",
      addressLine2: "",
      landMark: "",
    });
    setErrors({});
  };

  useImperativeHandle(ref, () => ({
    validate,
    resetForm,
    getFormData: () => formData,
  }));

  return (
    <div className="flex flex-col gap-8 mb-10 text-[18px] max-md:text-[16px] max-sm:text-[14px] font-medium font-inter text-justify px-10">
      <div className="flex flex-col gap-4">
        <label>
          Full Name <span className="text-[#db3131]">*</span>
        </label>
        <input
          type="text"
          name="fullName"
          value={formData.fullName}
          onChange={handleChange}
          className="p-2 bg-[#feedd4] border border-[#000000] rounded-[2px]"
        />
        {errors.fullName && (
          <span className="text-red-500 text-sm">{errors.fullName}</span>
        )}
      </div>

      <div className="flex flex-col gap-4">
        <label>
          Contact Number <span className="text-[#db3131]">*</span>
        </label>
        <input
          type="text"
          name="contactNumber"
          value={formData.contactNumber}
          onChange={handleChange}
          className="p-2 bg-[#feedd4] border border-[#000000] rounded-[2px]"
        />
        {errors.contactNumber && (
          <span className="text-red-500 text-sm">{errors.contactNumber}</span>
        )}
      </div>

      <div className="flex flex-col gap-4">
        <label className="font-bold">Delivery Address</label>

        <label>
          House/ Flat/ Floor Number/ Apartment{" "}
          <span className="text-[#db3131]">*</span>
        </label>
        <input
          type="text"
          name="addressLine1"
          value={formData.addressLine1}
          onChange={handleChange}
          className="p-2 bg-[#feedd4] border border-[#000000] rounded-[2px]"
        />
        {errors.addressLine1 && (
          <span className="text-red-500 text-sm">{errors.addressLine1}</span>
        )}

        <label>
          Road/Area <span className="text-[#db3131]">*</span>
        </label>
        <input
          type="text"
          name="addressLine2"
          value={formData.addressLine2}
          onChange={handleChange}
          className="p-2 bg-[#feedd4] border border-[#000000] rounded-[2px]"
        />
        {errors.addressLine2 && (
          <span className="text-red-500 text-sm">{errors.addressLine2}</span>
        )}

        <label>Landmark/ Direction to reach </label>
        <input
          type="text"
          name="landmark"
          value={formData.landmark}
          onChange={handleChange}
          className="p-2 bg-[#feedd4] border border-[#000000] rounded-[2px]"
        />
        {errors.landmark && (
          <span className="text-red-500 text-sm">{errors.landmark}</span>
        )}
      </div>
    </div>
  );
});

export default ContactForm;
