import React, { useContext } from "react";
import { ContextData } from "../../../../../context/Context";

function Mode({ pujaId, mode }) {
  const { setselectedmode, cartpujadata, setCartPujaData, selectedmode } =
    useContext(ContextData);

  const handlepujamode = (selectedmodeoption) => {
    const updatedmodedata = cartpujadata.map((pujadata) => {
      if (pujadata.pujaId === pujaId) {
        return {
          ...pujadata,
          mode: selectedmodeoption,
        };
      }
      return pujadata;
    });
    setCartPujaData(updatedmodedata);
    setselectedmode(selectedmodeoption);
  };

  return (
    <div className="w-full flex flex-col items-center my-4 gap-4">
      <div className="w-full flex flex-row max-lg:flex-col justify-between gap-4 cursor-pointer">
        {mode?.map((selectedmodeoption, index) => {
          const isSelected = selectedmodeoption === selectedmode;

          return (
            <div
              key={index}
              className={`w-full flex flex-row justify-center max-xl:flex-col items-center border-2 border-[#000000] rounded-[10px] text-[16px] font-bold px-4 py-4 gap-2 cursor-pointer ${
                isSelected ? "bg-[#E2D1F9]" : "bg-[#faecdf] opacity-40"
              }`}
              onClick={() => handlepujamode(selectedmodeoption)}
            >
              <span>{selectedmodeoption}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Mode;
