import React, { useContext } from "react";
import { ContextData } from "../../../../../context/Context";

function PujaSamagri({ samagri }) {
  const { havan, puja, yajman } = samagri;

  const {
    havanreadmore,
    sethavanreadmore,
    pujasreadmore,
    setpujasreadmore,
    yajmanreadmore,
    setyajmanreadmore,
  } = useContext(ContextData);

  return (
    <div>
      <div className="flex flex-col gap-8">
        <span className="text-[20px] max-md:text-[18px] font-sans non-italic font-semibold text-[#000000]">
          Havan
        </span>
        <div>
          <div
            className={
              havanreadmore
                ? "flex flex-row flex-wrap gap-4 h-24 overflow-hidden"
                : "flex flex-row flex-wrap gap-4"
            }
          >
            {havan.map((items, index) => {
              return (
                <div
                  key={index}
                  className="border-2 border-[#FF8213] text-[#000000] px-2 py-1 rounded-[10px]"
                >
                  {items}
                </div>
              );
            })}
          </div>
          {havanreadmore ? (
            <button
              onClick={() => sethavanreadmore(!havanreadmore)}
              className="text-[#FF8213]"
            >
              Read more
            </button>
          ) : (
            <button
              onClick={() => sethavanreadmore(!havanreadmore)}
              className="text-[#FF8213]"
            >
              Read less
            </button>
          )}
        </div>
      </div>

      <div className="flex flex-col gap-8 mt-10">
        <span className="text-[20px] max-md:text-[18px] font-sans non-italic font-semibold text-[#000000]">
          Puja
        </span>
        <div>
          <div
            className={
              pujasreadmore
                ? "flex flex-row flex-wrap gap-4 h-24 overflow-hidden"
                : "flex flex-row flex-wrap gap-4"
            }
          >
            {puja.map((items, index) => {
              return (
                <div
                  key={index}
                  className="border-2 border-[#FF8213] text-[#000000] px-2 py-1 rounded-[10px]"
                >
                  {items}
                </div>
              );
            })}
          </div>
          {pujasreadmore ? (
            <button
              onClick={() => setpujasreadmore(!pujasreadmore)}
              className="text-[#FF8213]"
            >
              Read more
            </button>
          ) : (
            <button
              onClick={() => setpujasreadmore(!pujasreadmore)}
              className="text-[#FF8213]"
            >
              Read less
            </button>
          )}
        </div>
      </div>

      <div className="flex flex-col gap-8 mt-10">
        <span className="text-[20px] max-md:text-[18px] font-sans non-italic font-semibold text-[#000000]">
          Yajman
        </span>
        <div>
          <div
            className={
              yajmanreadmore
                ? "flex flex-row flex-wrap gap-4 h-24 overflow-hidden"
                : "flex flex-row flex-wrap gap-4"
            }
          >
            {yajman.map((items, index) => {
              return (
                <div
                  key={index}
                  className="border-2 border-[#FF8213] text-[#000000] px-2 py-1 rounded-[10px]"
                >
                  {items}
                </div>
              );
            })}
          </div>
          {yajmanreadmore ? (
            <button
              onClick={() => setyajmanreadmore(!yajmanreadmore)}
              className="text-[#FF8213]"
            >
              Read more
            </button>
          ) : (
            <button
              onClick={() => setyajmanreadmore(!yajmanreadmore)}
              className="text-[#FF8213]"
            >
              Read less
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default PujaSamagri;
