import React, { useContext, useState } from "react";
import Modal from "@mui/material/Modal";
import { ContextData } from "../../context/Context";
import cross from "../../assets/cross.svg";
import LazyLoad from "react-lazyload";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

function GroupPujaCartModal({ cartpujadata }) {
  const {
    opengrouppujacartModal,
    setOpenGroupPujacartModal,
    handlegrouppujacartClose,
    setCurrentgrouptempledetail,
    setSpecialRequirement,
    setselectedmode,
    setCartGroupPujaData,
    finalgroupcartpujadata,
    setFinalGroupCartPujaData,
  } = useContext(ContextData);

  const [isExiting, setIsExiting] = useState(false);

  let finaltotal = 0;

  if (
    cartpujadata[0] &&
    cartpujadata[0].liveEvents &&
    cartpujadata[0].liveEvents.length > 0
  ) {
    const price = parseFloat(cartpujadata[0].liveEvents[0].price) || 0;
    const totalPrice =
      parseFloat(cartpujadata[0].liveEvents[0].totalPrice) || 0;
    finaltotal = price + totalPrice;
  }

  const handlecart = () => {
    const updatedCartItem = { ...cartpujadata[0], finaltotal };
    setFinalGroupCartPujaData([...finalgroupcartpujadata, updatedCartItem]);
    localStorage.setItem(
      "grouppujaData",
      JSON.stringify([...finalgroupcartpujadata, updatedCartItem])
    );
    setCartGroupPujaData([]);
    setselectedmode("");
    setSpecialRequirement("");
    setCurrentgrouptempledetail([]);
    setOpenGroupPujacartModal(false);
  };

  const handleClose = () => {
    setIsExiting(true);
    setTimeout(() => {
      handlegrouppujacartClose();
      setIsExiting(false);
    }, 500);
  };

  const modalVariants = {
    hidden: { opacity: 0, x: 300 },
    visible: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 300 },
  };

  return (
    <Modal
      open={opengrouppujacartModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEnforceFocus
    >
      <motion.div
        variants={modalVariants}
        initial="hidden"
        animate={isExiting ? "exit" : "visible"}
        transition={{ duration: 0.5 }}
        className={`flex flex-col justify-between absolute w-1/2 max-lg:w-2/3 max-md:w-3/4 max-sm:w-full h-full right-0 bg-white border-2 border-[#FF8213CC] shadow-xl overflow-y-auto max-h-screen`}
      >
        <div className="px-8">
          <div className="flex flex-row justify-between items-center mt-10">
            <span className="text-[28px] font-inter font-bold text-[#000000]">
              Your cart
            </span>
            <LazyLoad height={20}>
              <img
                src={cross}
                alt="cross"
                className="size-12 cursor-pointer"
                onClick={handleClose}
              />
            </LazyLoad>
          </div>
          <div className="flex flex-col">
            <div>
              {cartpujadata.map((currentselectedpuja) => {
                const {
                  pujaId,
                  pujaName,
                  pujaEventName,
                  imageurl,
                  liveEvents,
                } = currentselectedpuja;

                const pujaImage =
                  imageurl && imageurl[0] ? imageurl[0].toLowerCase() : null;
                let imageURL;

                if (pujaImage) {
                  const pujaimagearray = pujaImage.split("/");
                  imageURL = `https://${pujaimagearray[2]}.s3.amazonaws.com/${pujaimagearray[3]}/${pujaimagearray[4]}/${pujaimagearray[5]}`;
                } else {
                  imageURL = "";
                }

                return (
                  <div key={pujaId}>
                    <div className="flex flex-row justify-between items-center gap-4 font-inter w-full my-5">
                      <span className="text-[#71717A] text-[20px]">Puja</span>
                      <hr className="bg-[#d4d4d8] h-[2px] w-full" />
                    </div>
                    <div className="flex flex-row gap-8">
                      <LazyLoad height={50}>
                        <img
                          src={imageURL}
                          alt={pujaName}
                          className="size-20 rounded-[10px]"
                        />
                      </LazyLoad>
                      <div className="flex flex-col">
                        <span className="text-[18px] font-inter font-bold text-[#FF8213]">
                          {pujaName}
                        </span>
                        <div className="text-[18px] font-inter font-bold text-[#000000]">
                          Event Name:{" "}
                          <span className="text-[#FF8213]">
                            {pujaEventName}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row justify-between items-center gap-4 font-inter w-full my-5">
                      <span className="text-[#71717A] text-[20px]">Temple</span>
                      <hr className="bg-[#d4d4d8] h-[2px] w-full" />
                    </div>
                    {liveEvents.map((templedetails) => {
                      const {
                        templeId,
                        templeName,
                        templeLocation,
                        templeImageURL,
                      } = templedetails;

                      const pujaImage =
                        imageurl && imageurl[0]
                          ? imageurl[0].toLowerCase()
                          : null;
                      let imageURL;

                      if (pujaImage) {
                        const pujaimagearray = pujaImage.split("/");
                        imageURL = `https://${pujaimagearray[2]}.s3.amazonaws.com/${pujaimagearray[3]}/${pujaimagearray[4]}/${pujaimagearray[5]}`;
                      } else {
                        imageURL = "";
                      }

                      return (
                        <div className="flex flex-row gap-8" key={templeId}>
                          <LazyLoad height={50}>
                            <img
                              src=""
                              alt={templeName}
                              className="size-20 rounded-[10px]"
                            />
                          </LazyLoad>
                          <div className="flex flex-col">
                            <span className="text-[18px] font-inter font-bold text-[#FF8213]">
                              {templeName}
                            </span>
                            <div className="text-[18px] font-inter font-bold text-[#000000]">
                              Location:{" "}
                              <span className="text-[#FF8213]">
                                {templeLocation}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div className="flex flex-row justify-between items-center gap-4 font-inter w-full my-5">
                      <span className="text-[#71717A] text-[20px]">Price</span>
                      <hr className="bg-[#d4d4d8] h-[2px] w-full" />
                    </div>
                    <div className="text-[16px] font-inter font-semibold text-[#FF8213]">
                      <div className="text-[#000000] mb-4">
                        Temple Price:{" "}
                        <span className="text-[#FF8213]">
                          ₹ {cartpujadata[0].liveEvents[0].price}
                        </span>
                      </div>
                      <div className="text-[#000000] mb-4">Add-Ons</div>
                      <div>
                        {liveEvents[0].addOns.map((details) => {
                          const { addonId, name, price, newPrice, quantity } =
                            details;
                          if (quantity === 0) return null;
                          return (
                            <div key={addonId}>
                              <span className="text-[#000000] font-medium">
                                {name} :
                              </span>{" "}
                              ₹ {price} ✖ {quantity} = ₹ {newPrice}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div>
          <hr className="bg-[#d4d4d8] h-[2px] w-full" />
          <div className="flex flex-row justify-between gap-8 px-8 my-8">
            <Link to="/cart" onClick={handlecart}>
              <button className="text-[20px] font-inter text-[#000000] font-bold cursor-pointer">
                Add to Cart
              </button>
            </Link>
            <Link to="/">
              <div className="text-[16px] font-inter font-bold text-[#FF8213]">
                <span className="font-semibold text-[#000000]">
                  Total Price:{" "}
                </span>
                ₹ {finaltotal}
              </div>
            </Link>
          </div>
        </div>
      </motion.div>
    </Modal>
  );
}

export default GroupPujaCartModal;
