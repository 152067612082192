import React, { useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ContextData } from "../../../context/Context";

function NavigationItems() {
  const { setCurrentPuja, setCurrentTemplePuja, setCurrentGroupPuja } =
    useContext(ContextData);
  const location = useLocation();

  return (
    <div className="flex flex-row gap-4 items-center text-[#595959] font-sans text-[16px] font-semibold leading-6 not-italic">
      <NavLink to="/">
        <button>Home</button>
      </NavLink>
      <NavLink to="/product" onClick={() => setCurrentPuja(0)}>
        <button>Book a Pooja</button>
      </NavLink>
      <NavLink to="/kumbh">
        <button className="text-transparent bg-gradient-to-r from-[#ff9800] via-[#ff5757] to-[#ff9800] bg-[length:400%_400%] animate-gradient bg-clip-text">
          Maha Kumbh
        </button>
      </NavLink>

      <NavLink to="/temple" onClick={() => setCurrentTemplePuja(0)}>
        <button>Temple Services</button>
      </NavLink>
      <NavLink to="/group" onClick={() => setCurrentGroupPuja(0)}>
        <button>Group Puja</button>
      </NavLink>
      {location.pathname === "/" && (
        <a href="#about">
          <button>About us</button>
        </a>
      )}
      <a href="https://blog.ritividhi.com/">
        <button>Read</button>
      </a>
    </div>
  );
}

export default NavigationItems;
