import React, { useContext } from "react";
import { ContextData } from "../../../../../context/Context";
import OrderCard from "../../../../../components/cards/OrderCard";

function Orders() {
  const { pujaorderlist } = useContext(ContextData);

  return (
    <>
      <div className="flex flex-row max-md:flex-col justify-between max-md:gap-4">
        <h1 className="font-inter text-[32px] max-md:text-[24px] font-bold text-[#000000] mb-10 max-md:mb-5">
          Your Orders
        </h1>
        {/*<SearchOrder filterlist={pujaorderlist} />*/}
      </div>
      {pujaorderlist && pujaorderlist.length !== 0 ? (
        <div className="flex flex-col gap-8">
          {pujaorderlist.map((pujaorder) => {
            const {
              pujaId,
              orderId,
              orderDate,
              totalPrice,
              addOns,
              selectedDate,
              selectedTime,
              status,
            } = pujaorder;

            return (
              <div key={orderId}>
                <OrderCard
                  {...{
                    pujaId,
                    orderId,
                    orderDate,
                    totalPrice,
                    addOns,
                    selectedDate,
                    selectedTime,
                    status,
                  }}
                />
              </div>
            );
          })}
        </div>
      ) : (
        <div className="h-full">
          <div className="font-inter text-[#000000] text-[28px] max-md:text-[24px] font-bold flex w-full h-80 items-center justify-center">
            No Orders
          </div>
        </div>
      )}
    </>
  );
}

export default Orders;
