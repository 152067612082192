import React, { useContext } from "react";
import Heading from "../../../components/cards/Heading";
import LandingPageMainCard from "../../../components/cards/LandingPageMainCard";
import LandingPageCard from "../../../components/cards/LandingPageCard";
import Button from "../../../components/button/Button";
import { Link, useNavigate } from "react-router-dom";
import { ContextData } from "../../../context/Context";

function TempleServices() {
  let buttonName = "Temple Services";
  let headingdescription =
    "Experience Sacred Temple Services for Spiritual Harmony and Blessings";

  const { templesummary, setCurrenttempledetail, setCurrentTemplePuja } =
    useContext(ContextData);

  const navigate = useNavigate();
  const handletemplepuja = async (templeId) => {
    setCurrentTemplePuja(1);

    try {
      const response = await fetch(
        "https://app.ritividhi.com/ritividhi/temple-puja"
      );
      const currenttemplepuja = await response.json();
      const currenttemplepujadata = currenttemplepuja.filter(
        (temple) => temple.templeId === templeId
      );
      setCurrenttempledetail(currenttemplepujadata);
      navigate("/temple");
    } catch (error) {
      console.error("Error fetching all pujas summary", error);
    }
  };

  const handletemplelist = () => {
    setCurrentTemplePuja(0);
    navigate("/temple");
  };

  return (
    <>
      <div className="px-[8%] py-10">
        <div className="flex flex-col gap-8 max-lg:hidden">
          <div className="flex flex-row gap-4">
            <Heading {...{ buttonName, headingdescription }} />
            <>
              {templesummary.slice(0, 1).map((puja) => {
                const {
                  templeId,
                  templeName,
                  templeLocation,
                  description,
                  templeImage,
                } = puja;
                return (
                  <div key={templeId}>
                    <LandingPageMainCard
                      name={templeName}
                      templeLocation={templeLocation}
                      pujaImageURL={templeImage}
                      description={description}
                      buttonlabel="Book Now"
                      onClick={() => handletemplepuja(templeId)}
                    />
                  </div>
                );
              })}
            </>
          </div>
          <div className="grid grid-cols-3 gap-4">
            {templesummary.slice(1, 4).map((puja) => {
              const {
                templeId,
                templeName,
                templeLocation,
                description,
                templeImage,
              } = puja;
              return (
                <div key={templeId}>
                  <LandingPageCard
                    name={templeName}
                    templeLocation={templeLocation}
                    pujaImageURL={templeImage}
                    description={description}
                    buttonlabel="Book Now"
                    onClick={() => handletemplepuja(templeId)}
                  />
                </div>
              );
            })}
          </div>
        </div>

        <div className="hidden max-lg:flex max-lg:flex-col max-lg:gap-8">
          <Heading {...{ buttonName, headingdescription }} />
          <div className="grid grid-cols-2 gap-4">
            {templesummary.slice(0, 4).map((puja) => {
              const {
                templeId,
                templeName,
                templeLocation,
                description,
                templeImage,
              } = puja;
              return (
                <div key={templeId}>
                  <LandingPageCard
                    name={templeName}
                    templeLocation={templeLocation}
                    pujaImageURL={templeImage}
                    description={description}
                    buttonlabel="Book Now"
                    onClick={() => handletemplepuja(templeId)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="py-4 px-[8%] bg-[#FBD16E] flex flex-row items-center gap-16 max-md:hidden">
        <p className="text-[#000000] font-sans non-italic font-semibold">
          Looking for something else? Click 'View All' to explore more Pujas.
        </p>
        <Link onClick={() => handletemplelist()}>
          <Button
            label="View all temple services list"
            style={{
              backgroundColor: "#FBD16E",
              color: "black",
              borderColor: "#FF8213",
            }}
          />
        </Link>
      </div>
      <div className="hidden max-md:flex max-md:w-full max-md:justify-center">
        <Link onClick={() => handletemplelist()}>
          <Button
            label="View All"
            style={{
              backgroundColor: "#FBD16E",
              color: "black",
              borderColor: "#FFF1D0",
            }}
          />
        </Link>
      </div>
    </>
  );
}

export default TempleServices;
