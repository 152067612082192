import React, { useContext } from "react";
import Button from "../button/Button";
import { ContextData } from "../../context/Context";
import LazyLoad from "react-lazyload";
import grouppujamainimage from "../../assets/disposableassets/grouppujamainimage.png";

function CategoryPageCard({
  name,
  pujaImageURL,
  description,
  buttonlabel,
  time,
  onClick,
}) {
  const { pujasteps, currentpuja } = useContext(ContextData);

  let imageurl = "";

  if (pujaImageURL && pujaImageURL[0]) {
    const pujaImage = pujaImageURL[0].toLowerCase();
    const pujaImageArray = pujaImage.split("/");
    if (pujaImageArray.length >= 6) {
      imageurl = `https://${pujaImageArray[2]}.s3.amazonaws.com/${pujaImageArray[3]}/${pujaImageArray[4]}/${pujaImageArray[5]}`;
    }
  }

  const aboutpara = description.split(".");

  return (
    <div
      className="border border-2 border-[#B8F3DE] flex flex-col justify-between max-sm:gap-0 rounded-[20px] h-full cursor-pointer"
      style={{
        background: "linear-gradient(180deg, #FFF 20.5%, #EAF5F1 43.5%)",
      }}
      onClick={onClick}
    >
      <div className="px-4 pt-4 pb-2">
        <h1 className="text-[24px] max-md:text-[16px] max-sm:text-[12px] font-sans non-italic font-bold text-[#000000]">
          {name}
        </h1>
        <p className="text-[14px] max-sm:text-[12px] font-sans non-italic font-normal text-[#000000] max-md:mb-0 block max-sm:hidden">
          {aboutpara[0]}
        </p>
      </div>
      <div>
        {time ? (
          <div className="w-full flex justify-around items-center p-4 max-md:hidden">
            <div>
              {currentpuja < pujasteps.length - 1 && (
                <Button
                  label={buttonlabel}
                  style={{
                    backgroundColor: "#FBD16E",
                    color: "black",
                    borderColor: "#FF8213",
                  }}
                  onClick={onClick}
                />
              )}
            </div>
            <span className="text-[#595959] font-sans text-[16px] non-italic font-bold">
              {time}
            </span>
          </div>
        ) : (
          <div className="block w-full flex justify-end p-4 max-md:hidden">
            {currentpuja < pujasteps.length - 1 && (
              <Button
                label={buttonlabel}
                style={{
                  backgroundColor: "#FBD16E",
                  color: "black",
                  borderColor: "#FF8213",
                }}
                onClick={onClick}
              />
            )}
          </div>
        )}
        <LazyLoad height={100} offset={100}>
          <img
            src={imageurl || grouppujamainimage}
            alt={name}
            className="rounded-b-[20px]"
          />
        </LazyLoad>
      </div>
    </div>
  );
}

export default CategoryPageCard;
