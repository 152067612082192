import React, { useContext, useEffect, useState } from "react";
import { ContextData } from "../../context/Context";
import { Modal } from "@mui/material";
import cross from "../../assets/cross.svg";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";

function MahaKumbhRoomsModal() {
  const {
    mahakumbhpackagedata,
    mahakumbhroommodal,
    setMahaKumbhRoomModal,
    setMahaKumbhSelectedDate,
    mahakumbhselectedDate,
    mahakumbhadultquantity,
    setMahaKumbhAdultQuantity,
    mahakumbhroomquantity,
    setMahaKumbhRoomQuantity,
    setMahaKumbhPackageData,
    mahakumbhorderdata,
    setMahaKumbhOrderData,
    mahakumbhRoomtotal,
    setmahakumbhroomtotal,
  } = useContext(ContextData);

  const handleClose = () => {
    setMahaKumbhRoomModal(false);
    setMahaKumbhSelectedDate(null);
  };

  const handleModalClose = () => {
    setMahaKumbhRoomModal(false);
    toast("Please confirm traveller details to update the price.", {
      icon: "📋",
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
      },
    });
  };

  let formattedDate = "";
  let formattednextDate = "";

  if (mahakumbhselectedDate) {
    const extractedDate = mahakumbhselectedDate.$d;

    formattedDate = extractedDate.toISOString().split("T")[0];

    const newDate = new Date(extractedDate);
    newDate.setDate(
      newDate.getDate() +
        mahakumbhpackagedata.durationDays[0].totalDaysAsInt +
        1
    );
    formattednextDate = newDate.toISOString().split("T")[0];
  }

  const handleadultquantity = (oldAdultQuantity, newAdultQuantity) => {
    if (newAdultQuantity >= 2) {
      setMahaKumbhAdultQuantity(newAdultQuantity);
      const requiredRooms = Math.ceil(newAdultQuantity / 2);
      const newtotal = (mahakumbhpackagedata.pricing.basePrice / 2) * 1.8;
      if (oldAdultQuantity < newAdultQuantity) {
        setmahakumbhroomtotal(mahakumbhRoomtotal + newtotal);
      } else {
        setmahakumbhroomtotal(mahakumbhRoomtotal - newtotal);
      }
      setMahaKumbhRoomQuantity(requiredRooms);

      const updatedPackageData = {
        ...mahakumbhpackagedata,
        totalNoOfDays: mahakumbhpackagedata.durationDays[0].totalDaysAsInt,
        totalNumberOfAdults: newAdultQuantity,
        selectedNumberOfRooms: requiredRooms,
        totalNumberOfDevotees: newAdultQuantity,
      };

      localStorage.setItem(
        "mahakumbhpackage",
        JSON.stringify(updatedPackageData)
      );
      setMahaKumbhPackageData(updatedPackageData);

      const updatedorderData = {
        ...mahakumbhorderdata,
        totalNoOfDays: mahakumbhpackagedata.durationDays[0].totalDaysAsInt,
        totalNumberOfAdults: newAdultQuantity,
        selectedNumberOfRooms: requiredRooms,
        totalNumberOfDevotees: newAdultQuantity,
      };

      localStorage.setItem("mahakumbhorder", JSON.stringify(updatedorderData));
      setMahaKumbhOrderData(updatedorderData);
    }
  };

  return (
    <Modal
      open={mahakumbhroommodal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEnforceFocus
      className="bg-[#00000099]"
    >
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-3/4 max-md:w-full bg-[#FCF0E0] border-2 border-[#FF8213CC] shadow-xl px-8 py-5 rounded-[10px]">
        <div
          id="modal-modal-description"
          sx={{ mt: 2 }}
          className="flex flex-row gap-8 max-sm:gap-4 w-full p-4 max-sm:p-0"
        >
          <div className="flex flex-col gap-2 w-full">
            <div className="flex flex-row justify-between gap-2">
              <div className="flex flex-col gap-2">
                <h1 className="font-sans text-[24px] max-sm:text-[20px] text-[#850101] font-bold">
                  {mahakumbhpackagedata.packageName}
                </h1>
                <span className="font-sans text-[12px] text-[#850101] font-semibold">
                  {mahakumbhpackagedata.durationDays[0].days}
                </span>
              </div>
              <img
                src={cross}
                alt="cross"
                className="size-8 cursor-pointer mt-5"
                onClick={handleClose}
              />
            </div>
            <hr className="h-[1px] w-full bg-[#A9A9A9]" />
            <div className="flex flex-col gap-4">
              <div className="flex flex-col">
                <h1 className="font-sans text-[20px] text-[#850101] font-bold">
                  Selected Dates
                </h1>
                <span className="font-sans text-[12px] text-[#850101] font-semibold">
                  {formattedDate}
                  {" - "}
                  {formattednextDate}
                </span>
              </div>

              <div className="flex flex-row gap-8 max-sm:gap-4 mb-5 max-sm:mb-0">
                <div className="flex flex-col gap-2">
                  <p className="font-sans text-[20px] text-[#850101] font-bold">
                    Number of Devotees
                  </p>
                  <span className="font-sans text-[12px] text-[#850101] font-semibold">
                    Adult
                  </span>
                  <div className="flex flex-row gap-16 border border-[#850101] bg-[#FCF0E0] rounded-[20px] px-6 py-2 max-sm:gap-8">
                    <span
                      className="font-sans text-[20px] text-[#000000] font-semibold cursor-pointer"
                      onClick={() =>
                        handleadultquantity(
                          mahakumbhadultquantity,
                          mahakumbhadultquantity - 1
                        )
                      }
                    >
                      -
                    </span>
                    <span className="font-sans text-[20px] text-[#000000] font-bold">
                      {mahakumbhadultquantity}
                    </span>
                    <span
                      className="font-sans text-[20px] text-[#000000] font-semibold cursor-pointer"
                      onClick={() =>
                        handleadultquantity(
                          mahakumbhadultquantity,
                          mahakumbhadultquantity + 1
                        )
                      }
                    >
                      +
                    </span>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <p className="font-sans text-[20px] text-[#850101] font-bold">
                    Number of Rooms
                  </p>
                  <div className="flex flex-row gap-2">
                    <div className="flex flex-col gap-2">
                      <span className="font-sans text-[12px] text-[#850101] font-semibold">
                        Rooms
                      </span>
                      <div className="flex flex-row gap-16 border border-[#850101] bg-[#FCF0E0] rounded-[20px] px-24 py-2 max-sm:gap-8">
                        <span className="font-sans text-[20px] text-[#000000] font-bold">
                          {mahakumbhroomquantity}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Link to="/mahakumbhbooking" onClick={() => handleModalClose()}>
              <button className="bg-[#850101] px-12 py-3 text-[#ffffff] rounded-[20px] text-[16px] mt-5">
                Calculate Amount
              </button>
            </Link>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default MahaKumbhRoomsModal;
