import React from "react";
import meal from "../../../../../assets/mahakumbh/meal.svg";
import stay from "../../../../../assets/mahakumbh/stay.svg";
import wifi from "../../../../../assets/mahakumbh/wifi.svg";

function PackageOverview({ mahakumbhpackagedata }) {
  return (
    <div className="px-[8%] pb-10 pt-16 flex flex-col gap-4">
      <h1 className="font-sans text-[24px] max-sm:text-[18px] text-[#850101] font-bold">
        Package Overview
      </h1>
      <p className="font-sans text-[16px] max-sm:text-[14px] text-[#850101] font-normal">
        {mahakumbhpackagedata.description}
      </p>
      <h1 className="font-sans text-[24px]  max-sm:text-[16px] text-[#850101] font-bold mt-10">
        What this package offers
      </h1>
      <div className="flex flex-row max-sm:flex-col max-sm:gap-8 gap-16 my-5">
        <div className="flex flex-row gap-4 items-center">
          <img src={stay} alt="stay" />
          <span className="font-sans text-[16px] max-sm:text-[14px] text-[#850101] font-bold">
            Stay
          </span>
        </div>

        <div className="flex flex-row gap-4 items-center">
          <img src={meal} alt="meal" />
          <span className="font-sans text-[16px] max-sm:text-[14px] text-[#850101] font-bold">
            Meal
          </span>
        </div>

        <div className="flex flex-row gap-4 items-center">
          <img src={wifi} alt="wifi" />
          <span className="font-sans text-[16px] max-sm:text-[14px] text-[#850101] font-bold">
            Fast Wifi (100 Mbps)
          </span>
        </div>
      </div>
    </div>
  );
}

export default PackageOverview;
