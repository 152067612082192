import React from "react";
import shield from "../../../../../assets/grouppujaassets/shield.png";

function GroupPujaProcess({ pujaProcess }) {
  return (
    <div className="px-[8%] pt-24 max-md:pt-36 max-sm:pt-20 flex flex-col">
      <h1 className="text-[20px] max-md:text-[18px] font-bold font-inter text-[#00000] mb-10">
        Puja Process
      </h1>
      <div className="grid grid-cols-2 max-md:grid-cols-1 gap-16">
        {pujaProcess.map((process) => {
          const { index, pujaProcessTitle, pujaProcessSubTitle } = process;
          return (
            <div key={index} className="flex flex-row items-start gap-4">
              <img src={shield} alt="shield" className="size-8" />
              <div className="flex flex-col gap-4">
                <h1 className="text-[18px] max-md:text-[16px] font-bold font-inter text-[#00000]">
                  {pujaProcessTitle}
                </h1>

                <p className="text-[18px] max-md:text-[16px] text-justify font-normal font-inter text-[#00000]">
                  {pujaProcessSubTitle}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default GroupPujaProcess;
