import React, { useContext } from "react";
import { ContextData } from "../../../../../context/Context";
import searchicon from "../../../../../assets/searchicon.svg";

function SearchCategory() {
  const {
    gods,
    pujasummary,
    setPujaSummaryCopy,
    searchPujaQuery,
    setSearchPujaQuery,
  } = useContext(ContextData);

  const handleCategoryChange = (event) => {
    const selectedValue = event.target.value;

    if (selectedValue === "") {
      setPujaSummaryCopy(pujasummary);
    } else {
      const updatedCategory = pujasummary.filter(
        (puja) => puja.god[0] === selectedValue
      );
      setPujaSummaryCopy(updatedCategory);
    }
  };

  const handleInputChange = (e) => {
    const query = e.target.value;
    setSearchPujaQuery(query);

    if (query) {
      const filtered = pujasummary.filter((list) =>
        list.name.toLowerCase().includes(query.toLowerCase())
      );
      setPujaSummaryCopy(filtered);
    } else {
      setPujaSummaryCopy(pujasummary);
    }
  };

  return (
    <div className="px-[8%] py-5 bg-[#FFF2D4] border border-[#FFE3A2] flex flex-row max-md:flex-col gap-8">
      <div className="relative flex flex-row gap-4 items-center border-b-[1px] border-[#FF8213] px-8 py-1 flex-1">
        <input
          type="text"
          placeholder="Search for Puja, Temple services, Read blogs and many more"
          value={searchPujaQuery}
          onChange={handleInputChange}
          className="w-full px-4 py-2 bg-transparent placeholder-overflow-hidden placeholder-text-ellipsis placeholder-whitespace-nowrap placeholder-text-[6px] placeholder-font-quicksand placeholder-not-italic placeholder-font-normal placeholder-leading-normal placeholder-tracking-[0.2px] focus:outline-none"
        />
        <img
          src={searchicon}
          alt="searchicon"
          className="size-10 cursor-pointer"
        />
      </div>
      <div className="flex flex-row max-sm:flex-col justify-between gap-8 flex-1">
        <select
          name="category"
          id="category"
          className="bg-[#FF8213] text-white px-6 py-3 rounded-[40px] w-fit"
          onChange={handleCategoryChange}
        >
          <option value="">All Gods</option>
          {gods &&
            gods.map((god, index) => (
              <option key={index} value={god}>
                {god}
              </option>
            ))}
        </select>
      </div>
    </div>
  );
}

export default SearchCategory;
