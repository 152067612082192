import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ContextData } from "../../../context/Context";

function Categories() {
  const { setCurrentPuja, setCurrentTemplePuja, setCurrentGroupPuja } =
    useContext(ContextData);
  const filterlist = [
    {
      id: 1,
      filterName: "Maha Kumbh",
      link: "/kumbh",
    },
    {
      id: 2,
      filterName: "Pooja",
      link: "/product",
      onClick: () => setCurrentPuja(0),
    },
    {
      id: 3,
      filterName: "Temple Services",
      link: "/temple",
      onClick: () => setCurrentTemplePuja(0),
    },
    {
      id: 4,
      filterName: "Group Pooja",
      link: "/group",
      onClick: () => setCurrentGroupPuja(0),
    },
    {
      id: 5,
      filterName: "Read",
      link: "https://blog.ritividhi.com/",
    },
  ];

  return (
    <div className="flex flex-row justify-between items-start gap-4">
      {filterlist.map((filteritem) => (
        <Link to={filteritem.link} key={filteritem.id}>
          <button
            className={
              filteritem.id === 1
                ? //"text-[#ffffff] border border-[#000000] font-quicksand text-[12px] not-italic leading-normal font-semibold px-4 py-2 rounded-[20px] bg-gradient-to-r from-[#ff9800] via-[#ff5757] to-[#ff9800] bg-[length:400%_400%] animate-gradient"
                  "text-[#6F6F6F] border-[2px] border-[#ff9800] font-quicksand text-[12px] not-italic leading-normal font-semibold px-4 py-2 rounded-[20px] bg-[#FFF1D0]"
                : "text-[#6F6F6F] border border-[#000000] font-quicksand text-[12px] not-italic leading-normal font-semibold px-4 py-2 rounded-[20px] bg-[#FFF1D0]"
            }
            onClick={filteritem.onClick}
          >
            {filteritem.filterName}
          </button>
        </Link>
      ))}
    </div>
  );
}

export default Categories;
