import React, { useEffect } from "react";
import GroupPujaProgressSteps from "../../components/progresssteps/GroupPujaProgressSteps";
import NavBar from "../../components/navbar/NavBar";

function GroupPujaPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavBar />
      <GroupPujaProgressSteps />
    </>
  );
}

export default GroupPujaPage;
