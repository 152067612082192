import React, { useContext, useEffect, useState } from "react";
import { ContextData } from "../../context/Context";
import CartDataCard from "./section/CartDataCard";
import Footer from "../../components/footer/Footer";
import Button from "../../components/button/Button";
import CartTemplePujaCard from "./section/CartTemplePujaCard";
import CartGroupPujaCard from "./section/CartGroupPujaCard";
import NavBar from "../../components/navbar/NavBar";
import axios from "axios";

function CartPage() {
  const {
    finalcartpujadata,
    setFinalCartPujaData,
    pujatotal,
    setPujaTotal,
    templepujatotal,
    setTemplePujaTotal,
    grouppujatotal,
    setGroupPujaTotal,
    finaltemplecartpujadata,
    setFinalTempleCartPujaData,
    finalgroupcartpujadata,
    setFinalGroupCartPujaData,
    loggedIn,
    setOpenloginmodal,
    userid,
  } = useContext(ContextData);

  let isMahaKumbh = false;

  const [confirm, setconfirm] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setPujaTotal(finalcartpujadata?.totalPrice || 0);
  }, [finalcartpujadata]);

  useEffect(() => {
    setTemplePujaTotal(finaltemplecartpujadata?.totalPrice || 0);
  }, [finaltemplecartpujadata]);

  useEffect(() => {
    setGroupPujaTotal(finalgroupcartpujadata?.totalPrice || 0);
  }, [finalgroupcartpujadata]);

  const handlemoredetail = () => {};

  const handleremovedetail = () => {
    const removepujadetail = [];
    localStorage.setItem("pujaData", JSON.stringify(removepujadetail));
    setFinalCartPujaData(removepujadetail);
  };

  const handleremovetemplepujadetail = () => {
    const removetemplepujadetail = [];
    localStorage.setItem(
      "templepujaData",
      JSON.stringify(removetemplepujadetail)
    );
    setFinalTempleCartPujaData(removetemplepujadetail);
  };

  const handleremovegrouppujadetail = (pujaId) => {
    const removegrouppujadetail = [];
    localStorage.setItem(
      "grouppujaData",
      JSON.stringify(removegrouppujadetail)
    );
    setFinalTempleCartPujaData(removegrouppujadetail);
  };

  let finalpaymentamount = pujatotal + templepujatotal + grouppujatotal;

  //console.log(finalcartpujadata);
  //console.log(JSON.stringify(finalcartpujadata, null, 2));

  const handleorder = async () => {
    let token = localStorage.getItem("token");
    try {
      const res = await axios({
        method: "post",
        url: "https://app.ritividhi.com/ritividhi/orders/createOrder",
        data: finalcartpujadata,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      let merchantUserId = userid;
      let orderId = res.data.orderId;
      let amount = res.data.totalPrice;
      handlepayment(merchantUserId, orderId, amount, isMahaKumbh);
    } catch (error) {
      console.error("Error occurred during the request", error);
    }
  };

  const handlepayment = async (
    merchantUserId,
    orderId,
    amount,
    isMahaKumbh
  ) => {
    try {
      const res = await axios({
        method: "post",
        url: "https://app.ritividhi.com/ritividhi/payment/pay",
        data: { merchantUserId, orderId, amount, isMahaKumbh },
      });

      if (res.data?.data?.instrumentResponse?.redirectInfo?.url) {
        window.location.href =
          res.data.data.instrumentResponse.redirectInfo.url;
      } else {
        console.error("Redirect URL not found in the response.");
      }
    } catch (error) {
      console.error("Error occurred during the request", error);
    }
  };

  const handlelogin = () => {
    if (finalcartpujadata && finalcartpujadata.pujaId) {
      const updatedPujaData = {
        ...finalcartpujadata,
        userId: userid,
      };
      localStorage.setItem("pujaData", JSON.stringify(updatedPujaData));
      setFinalCartPujaData(updatedPujaData);
    } else if (finaltemplecartpujadata && finaltemplecartpujadata.templeId) {
      const updatedPujaData = {
        ...finaltemplecartpujadata,
        userId: userid,
      };
      localStorage.setItem("templepujaData", JSON.stringify(updatedPujaData));
      setFinalTempleCartPujaData(updatedPujaData);
    } else {
      console.error("No valid puja data available");
    }

    setconfirm(false);
    setOpenloginmodal(true);
  };

  const handleconfirm = () => {
    if (finalcartpujadata && finalcartpujadata.pujaId) {
      const updatedPujaData = {
        ...finalcartpujadata,
        userId: userid,
      };
      localStorage.setItem("pujaData", JSON.stringify(updatedPujaData));
      setFinalCartPujaData(updatedPujaData);
    }

    if (finaltemplecartpujadata && finaltemplecartpujadata.templeId) {
      const updatedPujaData = {
        ...finaltemplecartpujadata,
        userId: userid,
      };
      localStorage.setItem("templepujaData", JSON.stringify(updatedPujaData));
      setFinalTempleCartPujaData(updatedPujaData);
    }
    setconfirm(true);
  };

  return (
    <>
      <NavBar />
      {(finalcartpujadata && finalcartpujadata?.length !== 0) ||
      (finaltemplecartpujadata && finaltemplecartpujadata?.length !== 0) ? (
        <div className="px-[8%] py-10 flex flex-col gap-6">
          <div className="text-[32px] font-inter font-bold text-[#000000]">
            Cart
          </div>
          {finalcartpujadata && finalcartpujadata?.length !== 0 ? (
            <>
              <div className="text-[26px] font-inter font-bold text-[#000000]">
                Pujas
              </div>
              <div className="flex flex-col gap-8">
                {finalcartpujadata.pujaId && finalcartpujadata?.length !== 0 ? (
                  <>
                    <CartDataCard finalcartpujadata={finalcartpujadata} />
                    <div className="flex flex-row max-md:flex-col gap-4">
                      <div className="bg-[#FFF1D0] border border-[#FF8213] rounded-[10px] py-2 px-8 text-[16px] text-[#000000] font-sans font-bold">
                        More details will be sent to you shortly.
                      </div>
                      <Button
                        label="Remove"
                        style={{
                          backgroundColor: "#ff4d4f",
                          color: "#ffffff",
                          borderColor: "#ff4d4f",
                        }}
                        onClick={() => handleremovedetail()}
                      />
                    </div>
                  </>
                ) : null}
              </div>
            </>
          ) : null}

          <div className="text-[18px] font-inter font-semibold text-[#000000] w-full flex justify-end max-md:justify-between">
            Total Cost :{" "}
            <span className=" font-bold text-[#FF8213]">
              ₹ {finalpaymentamount}
            </span>
          </div>
          {loggedIn ? (
            <div className="w-full flex justify-center">
              {confirm ? (
                <Button
                  label="Pay"
                  style={{
                    backgroundColor: "transparent",
                    color: "black",
                    borderColor: "#FF8213",
                  }}
                  onClick={handleorder}
                />
              ) : (
                <Button
                  label="Confirm Order"
                  style={{
                    backgroundColor: "transparent",
                    color: "black",
                    borderColor: "#FF8213",
                  }}
                  onClick={handleconfirm}
                />
              )}
            </div>
          ) : (
            <div className="w-full flex justify-center">
              <Button
                label="Login to Continue"
                style={{
                  backgroundColor: "transparent",
                  color: "black",
                  borderColor: "#FF8213",
                }}
                onClick={handlelogin}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="h-full">
          <div className="font-inter text-[#000000] text-[28px] text-center max-md:text-[24px] font-bold flex w-full h-80 items-center justify-center">
            Embrace the Divine with RitiVidhi’s Sacred Rituals! <br />
            Add Pujas to get blessing
          </div>
        </div>
      )}
      <Footer />
    </>
  );
}

export default CartPage;
