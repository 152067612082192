import React, { useContext } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { ContextData } from "../../context/Context";
import { Modal } from "@mui/material";
import cross from "../../assets/cross.svg";
import dayjs from "dayjs";

function MahaKumbhCalender({ alloweddates }) {
  const {
    mahakumbhpackagedata,
    mahakumbhcalendermodal,
    setMahaKumbhCalendermodal,
    setMahaKumbhRoomModal,
    mahakumbhselectedDate,
    setMahaKumbhSelectedDate,
    mahakumbhselectedEndDate,
    setMahaKumbhSelectedEndDate,
    setMahaKumbhPackageData,
    mahakumbhadultquantity,
    mahakumbhchildquantity,
    mahakumbhroomquantity,
    mahakumbhorderdata,
    setMahaKumbhOrderData,
  } = useContext(ContextData);

  const handleClose = () => {
    setMahaKumbhCalendermodal(false);
    setMahaKumbhSelectedDate(null);
  };

  const handleDateChange = (date) => {
    let myDate = dayjs(date);
    let newDate = myDate.add(
      mahakumbhpackagedata.durationDays[0].totalDaysAsInt,
      "day"
    );
    setMahaKumbhSelectedEndDate(newDate.format());
    setMahaKumbhSelectedDate(date);
  };

  const handleNextClick = () => {
    setMahaKumbhCalendermodal(false);
    setMahaKumbhRoomModal(true);

    const updatedpujadateTime = {
      ...mahakumbhpackagedata,
      totalNumberOfDevotees: mahakumbhadultquantity + mahakumbhchildquantity,
      totalNoOfDays: mahakumbhpackagedata.durationDays[0].totalDaysAsInt,
      selectedStartDate: mahakumbhselectedDate,
      selectedEndDate: mahakumbhselectedEndDate,
      totalNumberOfAdults: mahakumbhadultquantity,
      totalNumberOfChildren: mahakumbhchildquantity,
      selectedNumberOfRooms: mahakumbhroomquantity,
      totalPrice: mahakumbhpackagedata.pricing.basePrice,
    };

    localStorage.setItem(
      "mahakumbhpackage",
      JSON.stringify(updatedpujadateTime)
    );
    setMahaKumbhPackageData(updatedpujadateTime);

    const updatedmahakumbhdateTime = {
      ...mahakumbhorderdata,
      totalNumberOfDevotees: mahakumbhadultquantity + mahakumbhchildquantity,
      totalNoOfDays: mahakumbhpackagedata.durationDays[0].totalDaysAsInt,
      selectedStartDate: mahakumbhselectedDate,
      selectedEndDate: mahakumbhselectedEndDate,
      totalNumberOfAdults: mahakumbhadultquantity,
      totalNumberOfChildren: mahakumbhchildquantity,
      selectedNumberOfRooms: mahakumbhroomquantity,
      totalPrice: mahakumbhpackagedata.pricing.basePrice,
    };

    localStorage.setItem(
      "mahakumbhorder",
      JSON.stringify(updatedmahakumbhdateTime)
    );
    setMahaKumbhOrderData(updatedmahakumbhdateTime);
  };

  const shouldDisableDate = (date) => {
    const shiftedDate = dayjs(date).add(0, "day");
    const formattedDate = shiftedDate.format("DD-MM-YYYY");
    return !alloweddates.includes(formattedDate);
  };

  return (
    <Modal
      open={mahakumbhcalendermodal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEnforceFocus
      className="bg-[#00000099]"
    >
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-2/4 max-md:w-3/4 max-sm:w-full bg-[#FCF0E0] border-2 border-[#FF8213CC] shadow-xl px-8 py-5 rounded-[10px]">
        <div className="flex flex row justify-between mb-10">
          <h1 className="font-sans text-[28px] text-[#850101] font-bold">
            Date of Booking
          </h1>
          <img
            src={cross}
            alt="cross"
            className="size-8 cursor-pointer"
            onClick={handleClose}
          />
        </div>
        <div
          id="modal-modal-description"
          sx={{ mt: 2 }}
          className="flex flex-col gap-2 w-full flex items-center"
        >
          <div className="bg-[#ffffff] w-fit">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar
                value={mahakumbhselectedDate}
                onChange={handleDateChange}
                shouldDisableDate={shouldDisableDate}
              />
            </LocalizationProvider>
          </div>
          {mahakumbhselectedDate && (
            <button
              className="bg-[#850101] px-12 py-3 text-[#ffffff] rounded-[20px] text-[16px] mt-5"
              onClick={() => handleNextClick()}
            >
              Next
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default MahaKumbhCalender;
