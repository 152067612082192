import React, { useEffect } from "react";
import IntroductionSection from "./sections/IntroductionSection";
import BlogFilter from "./sections/BlogFilter";
import MostLovedArticles from "./sections/MostLovedArticles";
import PoojaRituals from "./sections/PoojaRituals";
import Astrology from "./sections/Astrology";
import Footer from "../../components/footer/Footer";
import NavBar from "../../components/navbar/NavBar";

function BlogPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavBar />
      <IntroductionSection />
      <div className="px-[8%] py-10 flex flex-row items-center gap-4">
        <hr className="block w-full border-2 border-[#000000] max-md:hidden" />
        <span className="text-[32px] max-md:text-[26px] max-sm:text-[20px] font-bold font-sans text-[#000000] whitespace-nowrap">
          Discover, Learn, and Connect
        </span>
        <hr className="block w-full border-2 border-[#000000] max-md:hidden" />
      </div>
      <BlogFilter />
      <MostLovedArticles />
      <PoojaRituals />
      <Astrology />
      <Footer />
    </>
  );
}

export default BlogPage;
